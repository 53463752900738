module.exports = {
  translations: {
    About: "Over ons",
    "Custom covering": "Custom \n covering",
    Contact: "Contact",
    "Do not hesitate": "Aarzel niet",
    "To contact us": "Ons te contacteren",
    Examples: "Stalen",
    Gallery: "Galerij",
    "General info": "Algemene informatie",
    Address: "Adres",
    "Cars covering": "Auto's die",
    "in Wallonian Brabant": "in Waals-Brabant",
    "Want to change": "Wenst u de kleur van",
    "The color of your car?": "Uw wagen te veranderen?",
    "Request a meeting": "Afspraak nemen",
    Register: "Verzenden",
    "Show map": "Zie kaart",
    Colors: "Kleuren",
    Click: "Klik",
    "More covering": "Bovenop van esthetiek",
    "For your car": "Van uw voertuig",
    "When passion": "Wanneer passie",
    "Becomes art": "Een kunst wordt",
    // registration form
    Protection: "Protection",
    Covering: "Covering",
    Cleaning: "Schoonmaak",
    Completed: "Voltooid",
    Sorry: "Sorry",
    "Choose a date": "Kies een datum",
    "Type your full name": "Voor-en achternaam",
    "Type your email address": "Voor-en e-mailadres",
    "Type your phone number": "Voor-en telefoonnummer",
    "Type your car maker and model": "Autofabrikant en model",
    "Choose services": "Kies diensten",
    "request.success.title": "Uw aanvraag is ontvangen!",
    "request.success.body": "Een operator zal contact met u opnemen om de laatste stappen af te ronden.",
    "request.failure.title": "Uw verzoek is niet ontvangen!",
    "request.failure.body": "Probeer het later opnieuw of bel ons op ons nummer",
    // home
    "home.description.seo": "Custom covering",
    // about
    "Ceramic treatment": "Keramische behandeling",
    "Protection films": "Beschermingsfolies",
    Relooking: "Relooking",
    "about.section1": `De keramische behandeling is een innovatieve technologie die gebruikt wordt om uw koetswerk te beschermen. Echte keramiek beschikt over uitzonderlijke eigenschappen zoals weerstand tegen hitte, koude, slijtage, krassen maar ook tegen chemische producten. De behandeling kan ook aangebracht worden op heel het interieur, zetels, stuur en dashbord.`,
    "about.section2": `Ontdek onze beschermingsfolies voor koetswerk in ons erkend centrum Avery Dennison &reg;, Xpel &reg;, Hexis Bodyfence &reg;. Deze beschermingsfolies, ook PPF folies genoemd (Paint Protection Film) nieuwe generatie zijn bestemd voor wagens en motorfietsen en bieden de mogelijkheid uw koetswerk voor 100% te beschermen tegen impact, micro-krassen, krassen en zelf vandalisme. Deze beschermingsfolies zijn zeer resistent tegen impact van steentjes en micro-krassen. Hun zelf-verlittekende technologie zorgt voor regeneratie onder invloed van warmte. Onze beschermingsfolies voor koetswerk zorgen voor een duurzame en efficiënte bescherming van de verf van uw voertuig en geeft hen tegelijkertijd een meer intense glans.`,
    "about.section3": `U hebt de wagen gevonden die u bevalt maar de kleur staat u niet aan ? Geen enkel probleem voor onze kleurenfolie Avery Dennison &reg; supreme wrapping. Binnen enkele dagen verandert u de kleur, zonder de kostprijs van verf. Er bestaat een groot aantal kleuren en texturen. Daarenboven wordt uw voertuig beschermd tegen de zon en micro-krassen.`,
    "about.content": `NL Custom is ontstaan uit een passie voor mooie wagens en heeft ondertussen reeds 10 jaar ervaring en een onbetwistbare beheersing in het vak. Wenst u uw voertuigen te beschermen tegen alledaagse agressies ? Wij zijn er om u te dienen. Ontdek hieronder onze opties voor de bescherming.`,
    // gallery
    "gallery.description": "Case studies - echt werk, echte voorbeelden - geanimeerde rondleiding door ons werk",
    // examples
    "examples.content": `Dit is mogelijk zonder de kostprijs van verf dankzij de folies met Avery Dennison &reg; suprême wrapping kleuren. Personaliseer uw voertuig naargelang uw noden en/of verwachtingen in enkele dagen. Scrol om onze kleuren en texturen te testen.`,
    // extras
    Extras: "Extras",
    Reconditioning: "Herconditionering",
    "Technical inspection preparation": "Klaarmaken voor de technische controle",
    "Secure guarding": "Beveiligde bewaking",
    "Vehicle transfer": "Transfer van uw voertuig",
    "Intermediate sale-purchase": "Tussenpersoon aankoop-verkoop",
    "Sport cars rental": "Verhuur van sportwagens",
    "extras.section1": `In het nieuw zetten of diepe reiniging van uw wagen, zetels, tapijten, plafond et motorcompartiment.`,
    "extras.section2": `Schoonmaak van uw wagen en verificatie van alle veiligheids- en controlepunten voor de goedkeuring van de technische controle.`,
    "extras.section3": `Onze bewakingsdienst zorgt voor de toezicht op uw wagen gedurende uw afwezigheid. Transfer van en naar de luchthaven vanuit ons depot wordt verzekerd dankzij onze taxi service/partner zonder supplement.`,
    "extras.section4": `Overhaastig moeten terugkeren of uw voertuig ter plaatse moeten achterlaten. Hoe rapatriëren ? Doe beroep op onze diensten.`,
    "extras.section5": `Sinds tien jaar zijn wij actief in dit domein. Wij zetten onze kennis tot uw dienst. Vertrouw op ons!`,
    "extras.section6": `Komt binnenkort`,
    "extras.content": `Llende diensten aan teneinde uw wagen beter te beheren en een ideale rijervaring te garanderen.`
  }
};
