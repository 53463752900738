import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";

import { INTRO_VIDEO } from '../constants';

import "./home.css";

function Section(props) {
  const { t, ready } = props;
  const videoRef = React.useRef(null);
  const parts = ["outline", "focal", "fill"].map((it) => (
    <h1 data-scope={it} key={it}>
      {t("Custom covering")}
    </h1>
  ));
  useEffect(() => {
    if (ready) {
      let video = videoRef.current;
      if (video && video.paused) {
        video.play();
      }
    }
  }, [ready]);
  return (
    <section
      id="home"
      data-section="home"
      data-section-slug="/"
      data-section-label={t("Home")}
      data-section-icon="fa fa-home"
      data-section-exclude-from-menu="yes"
    >
      <div className="line"></div>
      <div className="bg-home">
        <div className="logo-wrapper">
          <div className="logo">&nbsp;</div>
        </div>
        <div className="taglineContainer">
          <div className="tagline">{parts}</div>
        </div>
        <div className="bg-video">
          <video playsInline muted paused="yes" ref={videoRef} src={INTRO_VIDEO}></video>
        </div>
        <div className="bg-face"></div>
      </div>
    </section>
  );
}

export const HomeSection = withTranslation("translations")(Section);
