import React from "react";

import { INSTAGRAM_URL } from "../constants";

import "./SocialButtons.css";

export function SocialButtons() {
  return (
    <ul className="socialButtons">
      <li data-network="instagram">
        <a href={INSTAGRAM_URL} target="_blank" rel="noopener noreferrer">
          <i className="fa fa-instagram"></i>
        </a>
      </li>
    </ul>
  );
}
