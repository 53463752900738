import React from "react";
import { render } from "react-dom";
import { I18nextProvider } from "react-i18next";

import i18n from "./i18n";
import App from "./App";

import "./main.mobile.css";

const { gsap } = window;

const Entry = () => {
  return (
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <App />
      </I18nextProvider>
    </React.StrictMode>
  );
};

window.scrollToSection = (section) => {
  let scrollTo = `#${section}`;
  if (section === "home") {
    scrollTo = 0;
  }
  gsap.to(window, {
    scrollTo,
    duration: 0.12
  });
};

window.mountApp = (node) => {
  const rootElement = document.getElementById("root");
  render(<Entry />, rootElement);
};
