import React from "react";
import { withTranslation } from "react-i18next";

import "./extras.css";

function Section(props) {
  const { t, descriptor } = props;
  const sections = [
    {
      title: t("Reconditioning"),
      body: t("extras.section1")
    },
    {
      title: t("Technical inspection preparation"),
      body: t("extras.section2")
    },
    {
      title: t("Secure guarding"),
      body: t("extras.section3")
    },
    {
      title: t("Vehicle transfer"),
      body: t("extras.section4")
    },
    {
      title: t("Intermediate sale-purchase"),
      body: t("extras.section5")
    },
    {
      title: t("Sport cars rental"),
      body: t("extras.section6")
    }
  ];
  const sectionContentText = t("extras.content");
  const stepSize = 96 / (sections.length - 1);
  return (
    <section id={descriptor.section} data-section={descriptor.section} data-section-label={descriptor.label}>
      <div className="line"></div>
      <div className="sectionHeader">
        <h1>{descriptor.count}</h1>
        <h2>{descriptor.label}</h2>
      </div>
      <div className="sectionContent">
        <h1>
          <span line="1">{t("More covering")}</span>
          <span line="2">{t("For your car")}</span>
        </h1>
        <p dangerouslySetInnerHTML={{ __html: sectionContentText }}></p>
      </div>
      <div className="subsectionsContainer">
        {sections.map((section, index) => {
          const sectionNumber = `${index + 1}`.padStart(2, "0");
          const numberValueStyle = {
            left: `calc(${stepSize}% * ${index} + ${index === 0 ? 48 / 2 : -48}px)`
          };
          return (
            <div className="subsectionContent" data-index={index} key={index}>
              <div className="number">
                <div className="numberValue" style={numberValueStyle}>
                  <span>{sectionNumber}</span>
                </div>
                <div className="numberLine"></div>
              </div>
              <div className="subsection">
                <h2>{section.title}</h2>
                <p>{section.body}</p>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}

export const ExtrasSection = withTranslation("translations")(Section);
