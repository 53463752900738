import React, { useRef, useCallback, useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { AwesomeButton } from "./AwesomeButton";
import { BookingDialog } from "./BookingDialog";

import "./BigMenu.css";

const { gsap, $ } = window;

let menuPageTimeline;
let menuPageTransition;
let menuPageAfterClose;

function openPageMenu($togglePageMenu) {
  $togglePageMenu.attr("data-state", "opened");
}

function closePageMenu($togglePageMenu) {
  $(".bgs").stop();
  $(".bgs").css({ display: "none" });
  $togglePageMenu.attr("data-state", "closed");
  if (menuPageAfterClose) {
    menuPageAfterClose();
    menuPageAfterClose = null;
  }
}

function createMenuPageAnimation($menuPageView, $togglePageMenu) {
  const yEnd = 0;
  const yStart = -1 * $menuPageView.height();
  menuPageTimeline = gsap.timeline({
    paused: true,
    onStart: () => {
      // console.debug("play complete", $(".bgs")[0]);
      $(".bgs").css({ display: "block" });
      $menuPageView.css("display", "block");
    },
    onComplete: () => {
      openPageMenu($togglePageMenu);
    },
    onReverseComplete: () => {
      // console.debug("reverse complete", $(".bgs")[0]);
      $(".bgs").css({ display: "none" });
      $menuPageView.css("display", "none");
      closePageMenu($togglePageMenu);
    }
  });
  menuPageTransition = gsap.fromTo(
    ".menuPageView",
    {
      top: yStart - 1000,
      ease: "power2",
      force3D: true
    },
    {
      top: yEnd,
      ease: "power2",
      force3D: true
    }
  );
  menuPageTimeline.add(menuPageTransition, 0);
  gsap.to(".bgs", { opacity: 1, force3D: true, duration: 5 });
  menuPageTimeline.add(
    gsap.from(".bigMenuItems li .sectionTitle ", {
      left: 0,
      ease: "power2",
      stagger: 0.15,
      x: "-100vw",
      force3D: true,
      onComplete: () => {
        const percentages = [0, 8, 16, 24];
        $(".bigMenuItems li .sectionTitle").each(function (index) {
          const $sectionTitle = $(this);
          $sectionTitle.css("left", `${percentages[index]}%`);
        });
        $(".bigMenuItems li a .sectionTitle").each(function (index) {
          $(this).attr("data-index", index);
        });
      }
    }),
    0
  );
  menuPageTimeline.add(
    gsap.from(".bigMenuItems li .sectionTitle .sectionCountSeparator ", {
      width: "70vw",
      ease: "power2",
      stagger: 0.25
    }),
    0
  );
  menuPageTimeline.play();
}

function onOpenMenuPageView(node) {
  const $menuPageView = $(".menuPageView");
  const $togglePageMenu = $(node);
  // animate
  if (menuPageTimeline) {
    menuPageTimeline.play().timeScale(1);
  } else {
    createMenuPageAnimation($menuPageView, $togglePageMenu);
  }
}

function onCloseMenuPageView(node, after) {
  // animate
  menuPageAfterClose = after;
  if (menuPageTimeline) {
    menuPageTimeline.reverse();
  }
}

function toggleMenuPageView(flag, node) {
  // console.debug("Toggle", flag, node);
  if (flag) {
    onOpenMenuPageView(node);
  } else {
    onCloseMenuPageView(node);
  }
}

function OpenIndicator(props) {
  const ref = useRef();
  const { opened } = props;
  const boxWidth = 25;
  const boxHeight = 10;
  const boxStyle = {
    width: `${boxWidth}px`,
    height: `${boxHeight}px`
  };
  const lineHeight = 2;
  const commonLineStyle = {
    position: "absolute",
    width: "100%",
    height: `${lineHeight}px`
  };
  const l2Style = {
    ...commonLineStyle,
    top: 0
  };
  const l1Style = {
    ...commonLineStyle,
    bottom: 0
  };
  const boxVCenter = (boxHeight - lineHeight) / 2;
  const { current: tl } = React.useRef(gsap.timeline({ paused: true }));
  useEffect(() => {
    const l1 = ref.current.querySelector(".OpenIndicatorL1");
    const l2 = ref.current.querySelector(".OpenIndicatorL2");
    tl.to(l2, {
      y: +1 * boxVCenter,
      rotate: +225
    });
    tl.to(
      l1,
      {
        y: -1 * boxVCenter,
        rotate: -225
      },
      0
    );
  }, [ref, tl, boxVCenter]);
  useEffect(() => {
    // console.debug("I was opened", opened, ref);
    if (opened) {
      tl.play();
    } else {
      tl.reverse();
    }
  }, [ref, opened, tl]);
  return (
    <div className="OpenIndicator" style={boxStyle} ref={ref}>
      <div className="OpenIndicatorL2" style={l2Style}>
        &nbsp;
      </div>
      <div className="OpenIndicatorL1" style={l1Style}>
        &nbsp;
      </div>
    </div>
  );
}

function BigMenuPage(props) {
  const { sections, withMenuPage, onSectionNavigate } = props;
  const menuPageViewRef = useRef();
  useEffect(() => {
    // console.debug("Transition", withMenuPage);
    toggleMenuPageView(withMenuPage, menuPageViewRef.current);
  }, [withMenuPage, menuPageViewRef]);
  const bigMenuBackground = (
    <div className="bgs">
      <div className="bg"></div>
      <div className="bg bg2"></div>
      <div className="bg bg3"></div>
    </div>
  );
  const onSectionClick = useCallback(
    (e) => {
      const section = e.currentTarget.getAttribute("data-menu-section");
      e.preventDefault();
      e.stopPropagation();
      onCloseMenuPageView(menuPageViewRef.current, () => {
        if (onSectionNavigate) {
          onSectionNavigate(section, e);
        }
      });
      return false;
    },
    [onSectionNavigate]
  );
  const menuSections = sections.filter(s => s.menu);
  return (
    <aside className="menuPageView" ref={menuPageViewRef}>
      <div className="logo-wrapper">
        <div className="logo">&nbsp;</div>
      </div>
      <ul className="bigMenuItems">
        {menuSections.map((section) => (
          <li key={section.section}>
            <a href={section.href} data-menu-section={section.section} onClick={onSectionClick}>
              <div className="sectionTitle">
                <div className="sectionCount">
                  <strong>{section.count}</strong>
                  <div className="sectionCountSeparator"></div>
                </div>
                <span className="sectionLabel">{section.label}</span>
              </div>
              <div className="sectionBack"></div>
            </a>
          </li>
        ))}
      </ul>
      {bigMenuBackground}
    </aside>
  );
}

function BigMenuBar(props) {
  const [withMenuPage, setWithMenuPage] = useState(false);
  const { t, sections } = props;
  const onShowBooking = useCallback(() => {
    BookingDialog.show();
  }, []);
  const onToggleMenuPage = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      // console.debug("Show menu page");
      setWithMenuPage(!withMenuPage);
      return false;
    },
    [withMenuPage]
  );
  const onSectionNavigate = useCallback((section) => {
    setWithMenuPage(false);
    window.scrollToSection(section);
  }, []);
  return (
    <>
      <ul className="miniPageMenu">
        <li>
          <AwesomeButton data-action="agenda.booking" label={t("Request a meeting")} onClick={onShowBooking} />
        </li>
        <li>
          <a href="#/action/toggle.menuPage" data-action="toggle.menuPage" onClick={onToggleMenuPage}>
            <OpenIndicator opened={withMenuPage} />
          </a>
        </li>
      </ul>
      <BigMenuPage sections={sections} withMenuPage={withMenuPage} onSectionNavigate={onSectionNavigate} />
    </>
  );
}

export const BigMenu = withTranslation("translations")(BigMenuBar);
