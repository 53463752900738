import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import isMobile from "ismobilejs";
// project
import "./main.css";
import { setupJQuery } from "./main.js";
// sections
import { HomeSection } from "./sections/home";
import { AboutSection } from "./sections/about";
import { ExamplesSection } from "./sections/examples";
import { GallerySection } from "./sections/gallery";
import { ExtrasSection } from "./sections/extras";
import { ContactSection } from "./sections/contact";
// components
import { Hud } from "./components/Hud";
// services
import { client } from "./api";

import "./App.css";

const { $, gsap } = window;
const mobile = isMobile(window.navigator);

function App(props) {
  const { t, i18n } = props;
  const [state, setState] = React.useState({
    config: {
      contact: {
        name: '',
        email: '',
        phone: '',
        address: {
          street: {
            name: '',
            number: '',
          },
          city: {
            name: '',
            code: '',
          },
          gps: [0, 0],
        },
      },
    },
    sections: [],
    colors: [],
    galleryItems: [],
    loaded: false,
    ready: false,
  })
  const { config, colors, loaded, ready, galleryItems } = state;
  const sectionProps = {
    isMobile: !!mobile.any,
  };
  const sectionFactories = {
    home: (d) => <HomeSection key={d.id} ready={ready} {...sectionProps} />,
    about: (d) => <AboutSection key={d.id} descriptor={d} {...sectionProps} />,
    gallery: (d) => <GallerySection key={d.id} descriptor={d} {...sectionProps} items={galleryItems} />,
    extras: (d) => <ExtrasSection key={d.id} descriptor={d} {...sectionProps} />,
    examples: (d) => <ExamplesSection key={d.id} descriptor={d} {...sectionProps} colors={colors} />,
    contact: (d) => <ContactSection key={d.id} descriptor={d} {...sectionProps} config={config} />,
  };
  const sections = state.sections.map(s =>  {
    s.label = t(s.label);
    return s;
  });
  useEffect(() => {
    if (!loaded) {
      Promise.all([
        client.fetchConfig(),
        client.fetchGallery(),
        client.fetchColors(),
        client.fetchSections(),
      ]).then((results) => {
        const [config, items, colors, sections] = results;
        setState((prev) => ({
          ...prev,
          sections: sections,
          galleryItems: items,
          config: config,
          colors: colors,
          loaded: true,
        }))
      }).catch((error) => {
        console.error('Unable to load', error);
        setState((prev) => ({
          ...prev,
          galleryItems: [],
          config: {},
          loaded: true,
        }))
      })
      return;
    }
    if (loaded && !ready) {
      const deps = { i18n, mobile, config };
      $("html").attr("data-loading-status", "complete");
      $("html").attr("data-is-mobile", mobile.any ? "yes" : "no");
      deps.gallery = galleryItems;
      deps.config = config;
      deps.colors = colors;
      window.config = config;
      if (colors.length) {
      setupJQuery(deps).then(() => {
        gsap.to(".App-loader-container", {
          scale: 0,
          alpha: 0,
          duration: 0.5,
          onComplete: () => {
            $("html").attr("data-ready", "yes");
            $(".App-loader-container").remove();
            setState((prev) => ({ ...prev, ready: true }));
          }
        });
      });
      }
    }
  }, [i18n, loaded, ready, config, colors, galleryItems, setState]);
  const sectionsWidget = loaded ? (
    <article className="sections">
      {sections.map(it => sectionFactories[it.section](it))}
    </article>
  ) : null;
  return (
    <div className="app" data-loaded={loaded ? "yes" : "no"}>
      <Hud sections={sections} config={config} />
      {sectionsWidget}
    </div>
  );
}

export default withTranslation("translations")(App);
