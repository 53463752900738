import React, { useCallback, useState } from "react";
import { withTranslation } from "react-i18next";

import "./about.css";

const { gsap } = window;

const hoverCursorImages = [
  {
    url: "img/cursors/hover1.jpg"
  },
  {
    url: "img/cursors/hover2.jpg"
  },
  {
    url: "img/cursors/hover3.jpg"
  }
];

function Subsection(props) {
  const { index, title } = props;
  const [section, setSection] = useState(props.section);
  const onMoreDetailsClicked = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const paragraph = document.querySelector(`dd[data-content-section-index="${index}"] > p`);
      const isExpanded = section.expanded;
      const mustExpand = !isExpanded;
      if (mustExpand) {
        paragraph.style.display = "block";
      }
      const next = mustExpand
        ? {
            height: "auto",
            opacity: 1
          }
        : {
            height: 0,
            opacity: 0
          };
      //gsap.killTweensOf(paragraph);
      gsap.to(paragraph, {
        ...next,
        force3D: true,
        onComplete: () => {
          setSection({
            ...section,
            expanded: !isExpanded
          });
        }
      });
    },
    [section, index]
  );
  const onMouseLeave = useCallback((e) => {
    //console.debug("leave", e.currentTarget, section, window.stage);
    window.stage.isOverContentSection = false;
    if (window.stage.cursorController) {
      window.stage.cursorController.restoreCursor();
    }
  }, []);
  return (
    <>
      <dt data-content-section-index={index} onMouseLeave={onMouseLeave}>
        <h2 dangerouslySetInnerHTML={{ __html: section.title }}></h2>
        <a href="#/" title={title} className="MoreDetailsButton" onClick={onMoreDetailsClicked}>
          <span>{section.expanded ? "-" : "+"}</span>
        </a>
      </dt>
      <dd data-content-section-index={index}>
        <p data-expanded={section.expanded ? "yes" : "no"} dangerouslySetInnerHTML={{ __html: section.body }}></p>
      </dd>
    </>
  );
}

function Section(props) {
  const { t, i18n, isMobile, descriptor } = props;
  const sections = [
    {
      title: t("Ceramic treatment"),
      body: t("about.section1"),
      expanded: false
    },
    {
      title: t("Protection films"),
      body: t("about.section2"),
      expanded: false
    },
    {
      title: t("Relooking"),
      body: t("about.section3"),
      expanded: false
    }
  ];
  const sectionContentText = t("about.content");
  const onMouseMove = useCallback((e) => {
    // console.debug("enter", e.currentTarget, section, window.stage);
    window.stage.isOverContentSection = true;
    if (window.stage.cursorController) {
      window.stage.cursorController.changeCursor({
        group: "content-section",
        mode: "plot",
        info: {
          source: hoverCursorImages,
          position: { x: e.pageX, y: e.pageY }
        }
      });
    }
  }, []);
  return (
    <section id={descriptor.id} data-section={descriptor.section} data-section-label={descriptor.label}>
      <div className="line"></div>
      <div className="sectionHeader">
        <h1>{descriptor.count}</h1>
        <h2>{descriptor.label}</h2>
      </div>
      <div className="sectionContent">
        <h1>
          <span line="1">{t("When passion")}</span>
          <span line="2">{t("Becomes art")}</span>
        </h1>
        <p dangerouslySetInnerHTML={{ __html: sectionContentText }}></p>
        <div className="sectionContentDetailFrame" data-frame="image.about">
          <div className="gradientFader"></div>
        </div>
        <div className="sectionContentDetail" onMouseMove={isMobile ? null : onMouseMove}>
          <dl>
            {sections.map((cs, index) => {
              return (
                <Subsection
                  key={`about-subsection-${index}-${i18n.language}`}
                  title={t("Expand")}
                  section={cs}
                  index={index}
                />
              );
            })}
          </dl>
        </div>
      </div>
    </section>
  );
}

export const AboutSection = withTranslation("translations")(Section);
