import React, { useCallback, useEffect, useRef } from "react";

import "./AwesomeButton.css";

const { gsap } = window;

export function AwesomeButton(props) {
  const iconRef = useRef();
  const labelRef = useRef();
  const highlightRef = useRef();
  const { current: master } = React.useRef(gsap.timeline({ paused: true }));
  useEffect(() => {
    // appear
    const rollOverAnim = gsap.timeline();
    rollOverAnim.to(iconRef.current, { x: -10 });
    rollOverAnim.to(labelRef.current, { x: +10 }, 0);
    rollOverAnim.to(highlightRef.current, { scaleX: 1, transformOrigin: "left center" }, 0);
    master.add(rollOverAnim);
    master.addLabel("rollOverStart", "<");
    master.addLabel("rollOverEnd", ">");

    const rollOutAnim = gsap.timeline();
    rollOutAnim.to(iconRef.current, { x: 0 });
    rollOutAnim.to(labelRef.current, { x: 0 }, 0);
    rollOutAnim.to(highlightRef.current, { scaleX: 0, transformOrigin: "right center" }, 0);
    master.add(rollOutAnim);
    master.addLabel("rollOutStart", "<");
    master.addLabel("rollOutEnd", ">");
  }, [iconRef, labelRef, highlightRef, master]);

  const onMouseOver = useCallback(() => {
    master.tweenFromTo("rollOverStart", "rollOverEnd");
  }, [master]);

  const onMouseOut = useCallback(() => {
    master.tweenFromTo("rollOutStart", "rollOutEnd");
  }, [master]);
  const icon = props.icon || "fa fa-chevron-right";
  return (
    <button onClick={props.onClick} onMouseOver={onMouseOver} onMouseOut={onMouseOut} className="AwesomeButton">
      <div className="AwesomeButtonElements">
        <i className={icon} ref={iconRef}></i>
        &nbsp;
        <span ref={labelRef}>{props.label}</span>
        <div className="buttonHighlight" ref={highlightRef}></div>
      </div>
    </button>
  );
}
