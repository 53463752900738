const translations = {
  About: "About",
  "Custom covering": "Custom \n covering",
  Contact: "Contact",
  "Do not hesitate": "Do not hesitate",
  "To contact us": "To contact us",
  Examples: "Sampling",
  Gallery: "Gallery",
  "General info": "General info",
  Address: "Address",
  "Cars covering": "Car covering",
  "in Wallonian Brabant": "in Wallonian Brabant",
  "Want to change": "Want to change",
  "The color of your car?": "The color of your car?",
  "Request a meeting": "Request a meeting",
  Register: "Register",
  "Show map": "Show map",
  Colors: "Colors",
  Click: "Click",
  "More covering": "More covering",
  "For your car": "For your car",
  "When passion": "When passion",
  "Becomes art": "Becomes art",
  // registration form
  Protection: "Protection",
  Covering: "Covering",
  Cleaning: "Cleaning",
  Completed: "Completed",
  Sorry: "Sorry",
  "Choose a date": "Choose a date",
  "Type your full name": "Type your full name",
  "Type your email address": "Type your email address",
  "Type your phone number": "Type your phone number",
  "Type your car maker and model": "Type your car maker and model",
  "Choose services": "Choose services",
  "request.success.title": "Your request was received!",
  "request.success.body": "An operator will contact you to finalize the last steps.",
  "request.failure.title": "Your request was not received!",
  "request.failure.body": "Try again later or give us a call on our number",
  // home
  // about
  "Ceramic treatment": "Ceramic treatment",
  "Protection films": "Protection films",
  Relooking: "Relooking",
  "about.section1": `Ceramic treatment is the technological innovation used to protect your body. Real ceramic has exceptional properties such as resistance to heat, cold, abrasion, scratches but also chemicals. The treatment is also applicable on the entire interior, seats, steering wheel, dashboard.`,
  "about.section2": `Check out our body protection films at our Avery Dennison Authorized Center &reg; , Xpel &reg; , Hexis Bodyfence &reg;. These protective films, also known as the new generation PPF (Paint Protection Film) for automobiles and motorcycles, provide 100% protection for your body from impacts, micro-scratches, scratches and even vandalism. These protective films are very resistant to stone impacts and are also very resistant to micro-scratches. Their self-healing technology allows them to regenerate on their own under the action of heat. Our body protection films allow you to permanently and effectively protect your car's paint but also give it a more intense shine.`,
  "about.section3": `Have you found the right car for you but don't like the color too much? This is no longer an issue with our Avery Dennison supreme wrapping color film. You could change the color in a few days, without the cost of a paint. There are a multitude of colors and textures. In addition, your car will be protected from the sun and micro-scratches.`,
  "about.content": `NL Custom started out of a passion for beautiful cars with over 10 years of experience in the sector and a solid mastery. Want to protect your vehicle from everyday attacks? Here we are to serve you. Find out about our protection options below.`,
  // gallery
  "gallery.description": "Case studies - real work, real examples - animated tour of our work",
  // examples
  "examples.content": `This is possible without the cost of painting with our Avery Dennison &reg; supreme wrapping. Personalize your vehicle according to your needs, tastes and / or expectations in a few days. Scroll to test our color and texture options.`,
  // extras
  Extras: "Extras",
  Reconditioning: "Reconditioning",
  "Technical inspection preparation": "Technical inspection preparation",
  "Secure guarding": "Secure guarding",
  "Vehicle transfer": "Vehicle transfer",
  "Intermediate sale-purchase": "Intermediate sale-purchase",
  "Sport cars rental": "Sport cars rental",
  "extras.section1": `Refurbishment of your vehicle or thorough cleaning, shampooing of the seats, carpets, ceiling light and engine compartment.`,
  "extras.section2": `Cleaning of your vehicle and checking of all the security points for a passage to the technical control.`,
  "extras.section3": `Our security service is responsible for guarding your vehicle during your absence. In addition, the transfer from our depot to the airport and the return to the airport will be taken care of with our taxi partner at no extra charge.`,
  "extras.section4": `Rushed back from vacation and you had to leave your vehicle behind. How to repatriate him? Ask our services.`,
  "extras.section5": `With a decade of experience in the purchase and sale of vehicles, we put our know-how at your service. Trust us!`,
  "extras.section6": `Coming soon`,
  "extras.content": `We provide various services to improve the management of your vehicle and your entire heating experience.`
};

module.exports = {
  translations
};
