import React from "react";
// components
import { LanguagesMenu } from "./LanguagesMenu";
import { BigMenu } from "./BigMenu";
import { SocialButtons } from "./SocialButtons";
import { Cursor } from "./Cursor";

export function Hud(props) {
  const { sections } = props;
  return (
    <div className="sectionsHud">
      <Cursor />
      <LanguagesMenu />
      <div className="sectionsProgressbarContainer"></div>
      <SocialButtons />
      <BigMenu sections={sections} />
    </div>
  );
}
