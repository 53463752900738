import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import Ajv from "ajv";
import addFormats from "ajv-formats";
import { I18nextProvider } from "react-i18next";
import { withTranslation } from "react-i18next";

import { client } from "../api";
import i18n from "../i18n";

import "./BookingDialog.css";

const ajv = new Ajv({ allErrors: true, useDefaults: true, verbose: true });
const { moment, grecaptcha, jQuery } = window;
const $ = jQuery;
function createValidator(schema) {
  const compiler = addFormats(ajv);
  compiler.addFormat("date-iso", (str) => /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(.\d{3})?((\+\d{2}:\d{2})|Z)/.test(str));
  const validate = compiler.compile(schema);
  return (data) => {
    const valid = validate(data);
    const validation = { valid, errors: validate.errors || [] };
    return validation;
  };
}
const SERVICES_LIST = [
  { key: "s0", selected: false, label: "Protection" },
  { key: "s1", selected: false, label: "Covering" },
  { key: "s2", selected: false, label: "Cleaning" }
];
const schema = {
  type: "object",
  properties: {
    verifyToken: { type: "string", minLength: 1 },
    date: { type: "string", format: "date-iso" },
    name: { type: "string", minLength: 1 },
    email: { type: "string", minLength: 1, format: "email" },
    phone: { type: "string", minLength: 1 },
    car: { type: "string", minLength: 1 },
    services: { type: "array", minItems: 1 },
    language: { type: "string", minLength: 1 },
  },
  required: ["verifyToken", "date", "name", "email", "phone", "car", "services", "language"]
};
const formValidator = createValidator(schema);

function getDefaultDate() {
  const defaultDate = moment().set({
    hour: 9,
    minute: 0,
    second: 0,
    millisecond: 0
  });
  const day = defaultDate.isoWeekday();
  if (day === 6) {
    defaultDate.add(2, "days");
  } else if (day === 7) {
    defaultDate.add(1, "days");
  } else {
    defaultDate.set({ hour: new Date().getHours() + 1 });
  }
  return defaultDate;
}

function BookingFormMessage(props) {
  const { result, success, failure } = props;
  let bookingFormMessage;
  if (result === "booking.completed") {
    bookingFormMessage = (
      <>
        <img src="img/check-mark.svg" alt={success.title} />
        <h3>{success.title}</h3>
        <p>{success.message}</p>
      </>
    );
  } else if (result === "booking.failed") {
    bookingFormMessage = (
      <>
        <img src="img/error.svg" alt={failure.title} />
        <h3>{failure.title}</h3>
        <p>{failure.message}</p>
      </>
    );
  }
  return <div className="bookingFormMessage">{bookingFormMessage}</div>;
}

function BookingDialogView(props) {
  const { t, i18n, requestHide } = props;
  const { language } = i18n;
  const formRef = useRef();
  const [pending, setPending] = useState(false);
  const [result, setResult] = useState(undefined);
  const [verifyToken, setVerifyToken] = useState("");
  const [date, setDate] = useState(getDefaultDate());
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [car, setCar] = useState("");
  const [services, setServices] = useState([...SERVICES_LIST]);
  const isAfterOperation = !pending && typeof result !== "undefined";
  const flatpickrRef = useRef(null);
  // eslint-disable-next-line
  const payload = {
    verifyToken,
    date: date.toDate().toISOString(),
    name,
    email,
    phone,
    car,
    services: services.filter((s) => s.selected),
    language
  };
  const toggleSelectedService = useCallback(
    (key) => {
      setServices((prev) => {
        const next = prev.map((s) => {
          const it = { ...s };
          if (it.key === key) {
            it.selected = !it.selected;
          }
          return it;
        });
        return next;
      });
    },
    [setServices]
  );
  const onServiceChange = useCallback(
    (e) => {
      e.persist();
      const changed = e.currentTarget.getAttribute("data-service");
      toggleSelectedService(changed);
    },
    [toggleSelectedService]
  );
  const onSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (isAfterOperation) {
        if (result === "booking.completed") {
          requestHide();
        } else if (result === "booking.failed") {
          requestHide();
        }
        return false;
      }
      setPending(true);
      client
        .register(payload)
        .then(() => {
          setResult("booking.completed");
          setPending(false);
        })
        .catch((error) => {
          console.error("Unable to complete registration", error);
          setResult("booking.failed");
          setPending(false);
        });
      return false;
    },
    [payload, isAfterOperation, result, requestHide]
  );
  const validation = formValidator(payload);
  const canSubmit = validation.valid && !pending;
  useEffect(() => {
    const $datePicker = $(flatpickrRef.current);
    $datePicker.flatpickr({
      minDate: "today",
      inline: true,
      weekNumbers: true,
      enableTime: true,
      dateFormat: "DD-MM-YYYY H:i",
      altFormat: "DD-MM-YYYY H:i",
      defaultDate: getDefaultDate().toDate(),
      time_24hr: true,
      disable: [
        (date) => {
          return date.getDay() === 0 || date.getDay() === 6;
        }
      ],
      locale: {
        firstDayOfWeek: 1
      },
      onChange: function (selectedDates) {
        setDate(moment(selectedDates[0]));
      }
    });
  }, [setDate]);
  useEffect(() => {
    const datePicker = flatpickrRef.current._flatpickr;
    datePicker.setDate(date.toDate(), false);
  }, [date]);
  useEffect(() => {
    // render the captcha widget
    grecaptcha.render("captcha", {
      sitekey: window.config.GOOGLE_RECAPTCHA_SITE_KEY,
      theme: 'light',
      hl : language,
      callback: (e) => setVerifyToken(e),
      "expired-callback": () => {
        grecaptcha.reset();
      }
    });
  }, [setVerifyToken, language]);
  useEffect(() => {
    const $bookingForm = $(formRef.current);
    const $calendar = $bookingForm.find(".flatpickr-calendar");
    if (pending) {
      $calendar.block({
        message: "",
        overlayCSS: { opacity: 0.05 }
      });
    } else {
      $calendar.unblock();
    }
    if (isAfterOperation) {
      $calendar.css("visibility", "hidden");
    } else {
      $calendar.css("visibility", "visible");
    }
  }, [isAfterOperation, pending, formRef]);
  const bookingFormProgressIndicatorStyle = {
    display: pending && typeof result === "undefined" ? "block" : "none"
  };
  const captchaStyle = {
    display: pending && typeof result === "undefined" ? "none" : "block"
  };
  const bookingFormMessageStyle = {
    visibility: isAfterOperation ? "hidden" : "visible"
  };
  const bookingFormFieldsStyle = {
    visibility: isAfterOperation ? "hidden" : "visible"
  };
  let formTitle = t("Request a meeting");
  let submitLabel = t("Register");
  let submitAction = "register.submit";
  if (isAfterOperation) {
    if (result === "booking.completed") {
      formTitle = t("Completed");
      submitLabel = t("Ok");
      submitAction = "register.close";
    }
    if (result === "booking.failed") {
      formTitle = t("Sorry");
      submitLabel = t("Ok");
      submitAction = "register.retry";
    }
  }
  return (
    <form className="form bookingForm" onSubmit={onSubmit} ref={formRef}>
      <fieldset>
        <legend>
          <span>{formTitle}</span>
        </legend>
        <button className="bookingFormCloseButton">&#10005;</button>
        <div className="bookingFormFields" style={bookingFormFieldsStyle}>
          <input
            ref={flatpickrRef}
            name="date"
            className="flatpickr"
            placeholder={t("Choose a date")}
            required
            disabled={pending}
          />
          <dl>
            <dt>{t("Full name")}</dt>
            <dd>
              <input
                type="text"
                name="fullName"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder={t("Type your full name")}
                required
                disabled={pending}
              />
            </dd>
            <dt>{t("eMail")}</dt>
            <dd>
              <input
                type="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder={t("Type your email address")}
                required
                disabled={pending}
              />
            </dd>
            <dt>{t("Phone")}</dt>
            <dd>
              <input
                type="phone"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder={t("Type your phone number")}
                required
                disabled={pending}
              />
            </dd>
            <dt>{t("Car make & model")}</dt>
            <dd>
              <input
                type="text"
                name="car"
                value={car}
                onChange={(e) => setCar(e.target.value)}
                placeholder={t("Type your car maker and model")}
                required
                disabled={pending}
              />
            </dd>
            <dt>{t("Type of service")}</dt>
            <dd>
              <div className="serviceChoices">
                <div className="serviceChoicesTitle">{t("Choose services")}</div>
                <div className="serviceChoicesList">
                  {services.map((service) => {
                    return (
                      <label key={service.key}>
                        <input
                          type="checkbox"
                          name="service-choices"
                          data-service={service.key}
                          disabled={pending}
                          checked={service.selected}
                          onChange={onServiceChange}
                        />
                        <span>{t(service.label)}</span>
                      </label>
                    );
                  })}
                </div>
              </div>
            </dd>
            <dd>
              <div id="captcha" style={captchaStyle}></div>
              <div className="bookingFormProgressIndicator" style={bookingFormProgressIndicatorStyle}>
                <div className="bookingFormProgressIndicatorImage"></div>
              </div>
            </dd>
          </dl>
        </div>
        <BookingFormMessage
          style={bookingFormMessageStyle}
          result={result}
          success={{
            title: t("request.success.title"),
            message: t("request.success.body")
          }}
          failure={{
            title: t("request.failure.title"),
            message: t("request.failure.body")
          }}
        />
        <div className="bookingFormActions">
          <button type="submit" data-action={submitAction} disabled={!canSubmit}>
            {submitLabel}
          </button>
        </div>
      </fieldset>
    </form>
  );
}

const TranslatedBookingDialog = withTranslation("translations")(BookingDialogView);

export const BookingDialog = (props) => {
  return (
    <React.StrictMode>
      <I18nextProvider i18n={i18n}>
        <TranslatedBookingDialog {...props} />
      </I18nextProvider>
    </React.StrictMode>
  );
};

BookingDialog.show = () => {
  const modal = document.createElement("div");
  $.blockUI({
    message: modal,
    onBlock: () => {
      $("body").css({ overflow: "hidden" });
      $(".blockOverlay")
        .off("click touchstart")
        .on("click touchstart", () => {
          BookingDialog.hide();
        });
      ReactDOM.render(
        <BookingDialog requestHide={() => BookingDialog.hide()} requestShow={() => BookingDialog.show()} />,
        modal
      );
      $(modal)
        .find(".bookingFormCloseButton")
        .off("click")
        .on("click", () => {
          BookingDialog.hide();
        });
      $(".blockPage").on("mousedown", (e) => {
        if (e.target === $(".blockPage")[0]) {
          BookingDialog.hide();
        }
      });
      $(document)
        .off("keyup")
        .on("keyup", (e) => {
          if (e.which === 27) {
            // Escape key
            BookingDialog.hide();
          }
        });
    },
    onUnblock: () => {
      $("body").css({ overflow: "inherit" });
      modal.remove();
    }
  });
};
BookingDialog.hide = () => {
  console.debug("Must hide the modal");
  $.unblockUI();
};
