import React, { useEffect, useRef, useState } from "react";

import "./CarColorPicker.css";

const { gsap, THREE, jQuery } = window;
const $ = jQuery;

export const CarColorPicker = (props) => {
  const { inlineItemsCount, colors } = props;
  const [withMenu, setWithMenu] = useState(false);
  const carColorPickerColorsRef = useRef();
  const colorPickerMenuRef = useRef();
  const { t } = props;

  const allColors = colors.reduce((acc, group) => {
    acc = acc.concat(group.items);
    return acc;
  }, []);
  const mainColors = allColors.filter(c => c.main);
  const inlineColors = mainColors.filter((it, idx) => idx < inlineItemsCount);
  const colorGroups = colors;

  const switchColor = (color) => {
    const { materials } = window.stage;
    const initial = new THREE.Color(materials.body.color.getHex());
    const value = new THREE.Color(color.color);
    gsap.to(initial, {
      r: value.r,
      g: value.g,
      b: value.b,
      onUpdate: function () {
        materials.body.color = initial;
      }
    });
  };
  const changeCarColor = (color) => {
    setWithMenu(false);
    switchColor(color);
  };
  const onCarColorPanelShow = (e) => {
    e.stopPropagation();
    e.preventDefault();
    setWithMenu(!withMenu);
    return false;
  };
  const onColorPickerMenuClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
    return false;
  };
  const onColorPickerColorClick = (e) => {
    e.persist();
    changeCarColor({ color: e.currentTarget.getAttribute("data-color") });
    e.stopPropagation();
    e.preventDefault();
    return false;
  };
  let colorPickerMenu = null;
  if (withMenu) {
    const style = { height: 0, opacity: 0 };
    colorPickerMenu = (
      <div className="carColorPickerMenu" ref={colorPickerMenuRef} onClick={onColorPickerMenuClick} style={style}>
        <table>
          <tbody>
            {colorGroups.map((group) => {
              const groupColors = group.items;
              return (
                <React.Fragment key={group.group}>
                  <tr>
                    <td colSpan="2">
                      <span className="colorGroup">{group.group}</span>
                    </td>
                  </tr>
                  {groupColors.map((color) => {
                    const style = {
                      backgroundColor: color.color
                    };
                    return (
                      <tr key={color.color} data-color={color.color} onClick={onColorPickerColorClick}>
                        <td>
                          <span
                            className="colorPreview"
                            style={style}
                            data-color={color.color}
                            data-gloss={color.gloss ? 'yes' : 'no'}
                            onClick={onColorPickerColorClick}
                          ></span>
                        </td>
                        <td>
                          <span className="colorName">{color.name}</span>
                        </td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
  useEffect(() => {
    if (carColorPickerColorsRef.current) {
      $(carColorPickerColorsRef.current)
        .find(".carColor")
        .tooltipster({
          animation: "fade",
          delay: 200,
          side: ["bottom", "bottom"]
        });
    }
  }, [carColorPickerColorsRef]);
  useEffect(() => {
    if (colorPickerMenuRef.current) {
      gsap.to(colorPickerMenuRef.current, { height: "50vh", opacity: 1, duration: 0.25 });
    }
    if (withMenu) {
      const onWindowClick = () => {
        window.removeEventListener("click", onWindowClick);
        setWithMenu(false);
      };
      window.addEventListener("click", onWindowClick);
    }
  }, [withMenu, setWithMenu, colorPickerMenuRef]);

  return (
    <div className="carColorPicker">
      <div className="carColorPickerColors" ref={carColorPickerColorsRef}>
        {inlineColors.map((color) => {
          const style = {
            backgroundColor: color.color
          };
          return (
            <div
              className="carColor"
              key={color.color}
              style={style}
              data-color={color.color}
              data-gloss={color.gloss ? 'yes' : 'no'}
              onClick={onColorPickerColorClick}
              title={color.name}
            >
              &nbsp;
            </div>
          );
        })}
        <a href="#/more-colors" className="carColor carColorDropdown" onClick={onCarColorPanelShow}>
          <div>{t("Colors")}</div>
          <div className="moreArrow">&#748;</div>
        </a>
      </div>
      {colorPickerMenu}
    </div>
  );
};
