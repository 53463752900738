import React from "react";
import { withTranslation } from "react-i18next";

import { CarColorPicker } from "../components/CarColorPicker";

import "./examples.css";

const { $ } = window;

function Section(props) {
  const { t, colors, descriptor } = props;
  const withoutInline = $(window).width() < 768;
  const sectionContentText = t("examples.content");
  return (
    <section id={descriptor.section} data-section={descriptor.section} data-section-label={descriptor.label}>
      <div className="background" data-3d-stage={descriptor.section}></div>
      <div className="line"></div>
      <div className="sectionHeader">
        <h1>{descriptor.count}</h1>
        <h2>{descriptor.label}</h2>
      </div>
      <div className="sectionContent">
        <h1>
          <span line="1">{t("Want to change")}</span>
          <span line="2">{t("The color of your car?")}</span>
        </h1>
        <p dangerouslySetInnerHTML={{ __html: sectionContentText }}></p>
      </div>
      <div className="sectionFooter">
        <CarColorPicker colors={colors} t={t} inlineItemsCount={withoutInline ? 0 : 7} />
      </div>
    </section>
  );
}

export const ExamplesSection = withTranslation("translations")(Section);
