// jshint ignore: start

const { THREE, Power2, gsap } = global;

export class Sketch {
  constructor(opts) {
    this.clicker = document.querySelector(".sectionGalleryContainer");
    this.container = document.querySelector(".sectionGalleryPreview");
    this.initiated = false;
    this.scene = new THREE.Scene();
    this.vertex = `varying vec2 vUv;void main() {vUv = uv;gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );}`;
    this.fragment = opts.fragment;
    this.uniforms = opts.uniforms;
    this.renderer = new THREE.WebGLRenderer();
    this.width = this.container.offsetWidth;
    this.height = this.container.offsetHeight;
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(this.width, this.height);
    this.renderer.setClearColor(0xeeeeee, 1);
    this.duration = opts.duration || 1;
    this.debug = opts.debug || false;
    this.easing = opts.easing || "easeInOut";
    this.images = opts.images;
    this.container.appendChild(this.renderer.domElement);
    this.camera = new THREE.PerspectiveCamera(70, this.width / this.height, 0.001, 1000);
    this.camera.position.set(0, 0, 2);
    this.time = 0;
    this.current = 0;
    this.textures = [];
    this.paused = true;
    this.onAnimationStart = opts.onAnimationStart;
    this.resize = this.resize.bind(this);
    this.render = this.render.bind(this)
  }

  setup() {
    return this.initiate(
      () =>
        new Promise((resolve) => {
          this.initiated = true;
          // console.debug("Init with current", this.current);
          this.setupResize();
          this.settings = { progress: 0.0 };
          this.addObjects();
          this.resize();
          this.clickEvent();
          this.play();
          if (this.onUpdate) {
            this.onUpdate(1, this.current);
          }
          if (this.onComplete) {
            this.onComplete(this.current);
          }
          resolve(this);
        })
    );
  }

  initiate(cb) {
    const promises = [];
    let that = this;
    this.images.forEach((url, i) => {
      let promise = new Promise((resolve) => {
        that.textures[i] = new THREE.TextureLoader().load(url, resolve);
      });
      promises.push(promise);
    });
    return Promise.all(promises).then(() => {
      cb();
    });
  }

  clickEvent() {
    this.clicker.addEventListener("click", (e) => {
      e.stopPropagation();
      e.preventDefault();
      this.next();
      return false;
    });
    this.clicker.addEventListener("swiped-left", (e) => {
      this.prev();
    });
    this.clicker.addEventListener("swiped-right", (e) => {
      this.next();
    });
  }

  setupResize() {
    window.addEventListener("resize", this.resize);
    if (this.textures.length && this.material) {
      this.resize();
    }
  }

  resize() {
    if (this.textures.length === 0) {
      return;
    }
    // console.debug('I am resizing');
    // image cover
    const tex = this.textures[this.current];
    tex.offset.set(1.5, 1.5);
    const imageAspect = tex.image.height / tex.image.width;
    const viewPortAspect = this.height / this.width;
    let a1 = 1;
    let a2 = 1;
    if (viewPortAspect > imageAspect) {
      a1 = imageAspect / viewPortAspect;
      a2 = 1;
    } else {
      a1 = 1;
      a2 = viewPortAspect / imageAspect;
    }
    this.material.uniforms.resolution.value.x = this.width;
    this.material.uniforms.resolution.value.y = this.height;
    this.material.uniforms.resolution.value.z = a1;
    this.material.uniforms.resolution.value.w = a2;
    const dist = this.camera.position.z;
    const height = 1;
    this.camera.fov = 2 * (180 / Math.PI) * Math.atan(height / (2 * dist));
    this.plane.scale.x = this.camera.aspect;
    this.plane.scale.y = 1;
    this.camera.updateProjectionMatrix();
  }

  addObjects() {
    let current = this.current;
    let next = 0;
    if (this.current < this.textures.length) {
      next = current + 1;
    }
    this.material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: "#extension GL_OES_standard_derivatives : enable"
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { type: "f", value: 0 },
        progress: { type: "f", value: 0 },
        border: { type: "f", value: 0 },
        intensity: { type: "f", value: 0 },
        scaleX: { type: "f", value: 40 },
        scaleY: { type: "f", value: 40 },
        transition: { type: "f", value: 40 },
        swipe: { type: "f", value: 0 },
        width: { type: "f", value: 0 },
        radius: { type: "f", value: 0 },
        texture1: { type: "f", value: this.textures[current] },
        texture2: { type: "f", value: this.textures[next] },
        displacement: {
          type: "f",
          value: new THREE.TextureLoader().load("img/disp1.jpg")
        },
        resolution: { type: "v4", value: new THREE.Vector4() }
      },
      // wireframe: true,
      vertexShader: this.vertex,
      fragmentShader: this.fragment
    });
    this.geometry = new THREE.PlaneGeometry(1, 1, 2, 2);
    this.plane = new THREE.Mesh(this.geometry, this.material);
    this.scene.add(this.plane);
  }

  stop() {
    this.paused = true;
  }

  play() {
    this.paused = false;
    this.render();
  }

  gotoFrame(frame, onComplete, onUpdate) {
    if (!this.initiated) {
      this.current = frame;
      this.onComplete = onComplete;
      this.onUpdate = onUpdate;
      return this.lastTransition;
    }
    if (this.isRunning || this.current === frame) return;
    // console.debug(this.current, 'Goto frame', frame);
    this.isRunning = true;
    if (this.onAnimationStart) {
      this.onAnimationStart(frame);
    }
    let nextTexture = this.textures[frame];
    this.material.uniforms.texture2.value = nextTexture;
    this.lastTransition = gsap.to(this.material.uniforms.progress, this.duration, {
      value: 1,
      ease: Power2[this.easing],
      onUpdate: () => {
        if (onUpdate) {
          onUpdate(this.lastTransition.progress(), frame);
        }
      },
      onComplete: () => {
        this.current = frame;
        this.material.uniforms.texture1.value = nextTexture;
        this.material.uniforms.progress.value = 0;
        this.isRunning = false;
        if (onComplete) {
          onComplete(frame);
        }
      }
    });
    return this.lastTransition;
  }

  next() {
    const next = this.current + 1 >= this.textures.length ? 0 : this.current + 1;
    return this.gotoFrame(next, this.onComplete, this.onUpdate);
  }

  prev() {
    const prev = this.current - 1 < 0 ? this.textures.length - 1 : this.current - 1;
    return this.gotoFrame(prev, this.onComplete, this.onUpdate);
  }

  render() {
    if (this.paused) return;
    this.time += 0.05;
    this.material.uniforms.time.value = this.time;
    // this.material.uniforms.progress.value = this.settings.progress;
    // this.camera.position.z = 3;
    // this.plane.rotation.y = 0.4*Math.sin(this.time)
    // this.plane.rotation.x = 0.5*Math.sin(0.4*this.time)
    requestAnimationFrame(this.render);
    this.renderer.render(this.scene, this.camera);
  }
}

export const createGallerySketch = (opts) => {
  const gallerySketch = new Sketch({
    onAnimationStart: opts.onAnimationStart,
    images: opts.images,
    duration: opts.duration,
    debug: false,
    easing: "easeOut",
    uniforms: {
      // width: {value: 0.35, type:'f', min:0., max:1},
    },
    fragment: `
		uniform float time;
		uniform float progress;
		uniform float width;
		uniform float scaleX;
		uniform float scaleY;
		uniform float transition;
		uniform float radius;
		uniform float swipe;
		uniform sampler2D texture1;
		uniform sampler2D texture2;
		uniform sampler2D displacement;
		uniform vec4 resolution;

		varying vec2 vUv;
		varying vec4 vPosition;
		vec2 mirrored(vec2 v) {
			vec2 m = mod(v,2.);
			return mix(m,2.0 - m, step(1.0 ,m));
		}

		void main()	{
		  vec2 newUV = (vUv - vec2(0.5))*resolution.zw + vec2(0.5);
		  vec4 noise = texture2D(displacement, mirrored(newUV+time*0.04));
		  // float prog = 0.6*progress + 0.2 + noise.g * 0.06;
		  float prog = progress*0.8 -0.05 + noise.g * 0.06;
		  float intpl = pow(abs(smoothstep(0., 1., (prog*2. - vUv.x + 0.5))), 10.);

		  vec4 t1 = texture2D( texture1, (newUV - 0.5) * (1.0 - intpl) + 0.5 ) ;
		  vec4 t2 = texture2D( texture2, (newUV - 0.5) * intpl + 0.5 );
		  gl_FragColor = mix( t1, t2, intpl );

		}

  `
  });
  return gallerySketch;
};
