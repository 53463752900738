import React from "react";
import { useTranslation } from "react-i18next";

import "./Cursor.css";

export function Cursor() {
  const { t } = useTranslation();
  return (
    <div className="cursor" data-cursor-size="100">
      <div className="cursorPointer">
        <span>{t("Click")}&nbsp;&rarr;</span>
      </div>
      <div className="cursorBackground"></div>
    </div>
  );
}
