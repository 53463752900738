import React, { useCallback } from "react";
import { withTranslation } from "react-i18next";
import isMobile from "ismobilejs";

import { BookingDialog } from "../components/BookingDialog";
import { AwesomeButton } from "../components/AwesomeButton";

import "./contact.css";

const mobile = isMobile(window.navigator);

function Section(props) {
  const { t, config, descriptor } = props;
  const onSubmit = useCallback(() => {
    BookingDialog.show();
  }, []);
  const onShowMap = () => {
    const MAP_GPS = contact.address.gps;
    const GOOGLE_MAPS_URI = `https://www.google.com/maps/search/?api=1&query=${MAP_GPS[0]},${MAP_GPS[1]}&zoom=14`;
    const APPLE_MAPS_URI = `https://maps.apple.com/maps?q=${MAP_GPS[0]},${MAP_GPS[1]}`;
    const mapUrl = mobile.apple.device ? APPLE_MAPS_URI : GOOGLE_MAPS_URI;
    window.open(mapUrl, "_blank");
  };
  const currentYear = new Date().getFullYear();
  const { contact } = config;
  return (
    <section id={descriptor.section} data-section={descriptor.section} data-section-label={descriptor.label}>
      <div className="line"></div>
      <div className="sectionHeader">
        <h1>{descriptor.count}</h1>
        <h2>{descriptor.label}</h2>
      </div>
      <div className="sectionContent">
        <h1>
          <span line="1">{t("Do not hesitate")}</span>
          <span line="2">{t("To contact us")}</span>
        </h1>
        <div className="sectionContentColumns">
          <address>
            <h2>{t("General info")}</h2>
            <p>
              <a href={`mailto:${contact.email}`}>{contact.email}</a>
            </p>
            <p>
              <a href={`tel:${contact.phone}`}>{contact.phone}</a>
            </p>
            <AwesomeButton label={t("Request a meeting")} onClick={onSubmit} id="bookNow" />
          </address>
          <address>
            <h2>{t("Address")}</h2>
            <p>{contact.address.street.name} <strong>{contact.address.street.number}</strong></p>
            <p><strong>{contact.address.city.code}</strong> {contact.address.city.name}</p>
            <AwesomeButton label={t("Show map")} onClick={onShowMap} />
          </address>
        </div>
      </div>
      <div className="sectionFooter">
        <span>&copy; {currentYear} | NL Custom</span>
      </div>
    </section>
  );
}

export const ContactSection = withTranslation("translations")(Section);
