import { createGallerySketch } from "./3d-sketch";
import { RoomEnvironment } from "./3d-envs";

import { INTRO_VIDEO, CAR_MODEL } from './constants';

export function setupJQuery(deps) {
  const {
    jQuery,
    gsap,
    ScrollTrigger,
    ScrollToPlugin,
    THREE,
    DRACOLoader,
    GLTFLoader,
    OrbitControls,
    // dat,
    createjs,
    scrollToSection,
    Sentry,
  } = window;
  const $ = jQuery;

  // console.debug(i18n);

  // Setup blockUI globally
  $.blockUI.defaults.css = { cursor: "default" };
  $.blockUI.defaults.overlayCSS = {
    cursor: "default",
    opacity: 0.25,
    backgroundColor: "#000"
  };
  $.blockUI.defaults.message = null;
  $.blockUI.defaults.fadeIn = 0;
  $.blockUI.defaults.fadeOut = 0;
  $.blockUI.defaults.fadeTime = 0;
  $.blockUI.defaults.showOverlay = true;

  ScrollTrigger.defaults({
    immediateRender: false,
    ease: "power1.inOut"
  });

  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(ScrollToPlugin);

  Sentry.init({
    dsn: 'https://972754980bbf45dcb889f273257e61be@o514053.ingest.sentry.io/5616767',
    tracesSampleRate: 1.0,
  });

  let scene, camera, renderer;
  let model;
  let wheels = [];
  let canRotateWheels = true;
  let rotationDirection = 1;
  let isBookingMode = false;
  let triggers = {};

  const ratio = window.innerWidth < 1300 ? window.innerWidth / 1300 : 1;

  const sceneAttributes = {
    camera: {
      position: {
        x: 4.25,
        y: 2.3,
        z: -40.5
      },
      rotation: {
        x: -2.75,
        y: 0.72,
        z: 2.88
      },
      scale: {
        x: 1,
        y: 1,
        z: 1
      }
    },
    model: {
      position: {
        x: 0,
        y: 0,
        z: 0
      },
      rotation: {
        x: 0,
        y: 0,
        z: 0
      },
      scale: {
        x: ratio,
        y: ratio,
        z: ratio
      }
    }
  };
  const $app = $(".app");
  let $progressBar;
  let $languagesMenu;
  let $miniPageMenu = $(".miniPageMenu");
  let $socialButtons = $(".socialButtons");
  let isOverGallery = false;
  let cursorAnimation;
  const $cursor = $(".cursor");
  const $togglePageMenu = $('.miniPageMenu li [data-action="toggle.menuPage"]');
  // elements
  const sectionNames = [];
  const $sections = $("section");
  const sectionsAnimationProgress = {
    sections: {},
    progress: 0
  };
  // console.debug($sections);
  $sections.each(function () {
    const $section = $(this);
    const section = $section.attr("data-section");
    sectionsAnimationProgress.sections[section] = {
      y: $section.position().top,
      h: $section.height(),
      progress: 0,
      viewportProgress: 0
    };
    sectionNames.push(section);
  });

  const $sectionsLines = $("section > .line");
  const sectionPositions = () => {
    const positions = [];
    $sectionsLines.each(function () {
      const $line = $(this);
      const $section = $line.parent();
      const position = {
        section: $section.attr("data-section"),
        s: Math.floor($line.offset().top),
        e: 0,
        h: Math.floor($line.offset().top) + $section.height(),
        p: 0
      };
      positions.push(position);
    });
    for (let p = 0; p < positions.length; p += 1) {
      const current = positions[p];
      const next = positions[p + 1];
      if (next) {
        current.e = next.s;
      } else {
        current.e = current.s + $('[data-section="' + current.section + '"]').height();
      }
      current.p = Math.max(0, Math.min((window.scrollY - current.s) / (current.e - current.s), 1));
    }
    return positions;
  };

  function updateProgress() {
    const positions = sectionPositions();
    const percentage =
      positions.slice(0, -1).reduce((acc, it) => {
        acc += it.p;
        return acc;
      }, 0) /
      (positions.length - 1);
    $progressBar.tpProgressBar("setProgress", percentage * 100);
    // console.debug(JSON.stringify(positions, null, 2));
  }

  function onSectionAnimationProgress(section, progress) {
    const sectionPercent = Math.round(progress * 100);
    sectionsAnimationProgress.sections[section].progress = sectionPercent;
    updateProgress();
  }

  function setupPlugins(sections, opts) {
    $.fn.matchHeight._maintainScroll = true;
    // languages switcher
    $languagesMenu = $(".languagesMenu");
    // mouse follower
    // Progressbar
    const ticks = sections;
    $progressBar = $(".sectionsProgressbarContainer");
    $progressBar.tpProgressBar({
      tickHeight: 2,
      progress: opts && opts.initialProgress ? opts.initialProgress : 0,
      tickRenderer: (tick, index) => {
        const $link = $("<a>").attr({
          href: tick.href,
          title: tick.label
        });
        let content = "";
        if (tick.icon) {
          const $icon = $('<i aria-hidden="true"></i>').addClass(tick.icon);
          content = $("<span>").append($icon);
        } else {
          content = $("<span>").text(tick.count || index);
        }
        $link.off("click").on("click", function (e) {
          e.preventDefault();
          e.stopPropagation();
          scrollToSection(tick.section);
          return false;
        });
        $link.append(content);
        return $link;
      },
      ticks
    });
    $progressBar.find("a").tooltipster({
      animation: "fade",
      delay: 200,
      side: ["right", "bottom"]
    });
  }

  function setupSections() {
    const items = [];
    // extract menus from section elements
    let count = 0;
    $sections.each(function () {
      const $section = $(this);
      const section = $section.attr("data-section");
      const slug = $section.attr("data-section-slug") || section;
      const label = $section.attr("data-section-label");
      const icon = $section.attr("data-section-icon");
      const excludeFromMenu = $section.attr("data-section-exclude-from-menu") === "yes";
      if (label) {
        const id = `section-${count}`;
        items.push({
          section,
          id,
          href: slug ? `#${slug}` : `#${id}`,
          count: `${count}`.padStart(2, "0"),
          label,
          icon,
          menu: !excludeFromMenu
        });
        $section.attr("data-section-index", count);
        $section.attr("id", slug ? slug : id);
        count += 1;
      }
    });
    $sections.matchHeight({
      property: "min-height",
      target: $('[data-section="home"]')
    });
    return items;
  }

  function getCurrentNavigationProgress() {
    const scrollTop = window.scrollY;
    const docHeight = document.body.offsetHeight;
    const winHeight = window.innerHeight;
    const scrollPercent = (scrollTop / (docHeight - winHeight)) * 100;
    return scrollPercent;
  }

  const materials = {
    body: null,
    details: null,
    glass: null
  };

  function setupSectionExamples3DStage($outlet) {
    let grid;
    let controls;
    function withCar(done) {
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath("wasm/");
      const loader = new GLTFLoader();
      loader.setDRACOLoader(dracoLoader);
      loader.load(CAR_MODEL, function (gltf) {
        const lightsMaterial = new THREE.MeshPhysicalMaterial({
          fog: false,
          color: 0xFF0000,
          metalness: 0,
          roughness: 0.4,
          clearcoat: 0.05,
          clearcoatRoughness: 0.05
        });
        const interiorMaterial = new THREE.MeshPhysicalMaterial({
          fog: false,
          color: 0x000000,
          metalness: 0,
          roughness: 0.4,
          clearcoat: 0.05,
          clearcoatRoughness: 0.05
        });
        const bodyMaterial = new THREE.MeshPhysicalMaterial({
          fog: false,
          color: 0x1d1d1b,
          metalness: 0,
          roughness: 0.4,
          clearcoat: 0.05,
          clearcoatRoughness: 0.05
        });
        const rimMaterial = new THREE.MeshStandardMaterial({
          fog: false,
          color: 0xFFFFFF,
          metalness: 1.0,
          roughness: 0.5
        });
        const detailsMaterial = new THREE.MeshStandardMaterial({
          fog: false,
          color: 0xCCCCCC,
          metalness: 1.0,
          roughness: 0.1
        });
        const glassMaterial = new THREE.MeshPhysicalMaterial({
          fog: false,
          color: 0xFFFFFF,
          opacity: 1,
          metalness: 0.0,
          roughness: 0.69,
          transmission: 0.5,
          transparent: true
        });
        //
        const carModel = gltf.scene.children[0];
        materials.body = bodyMaterial;
        materials.details = detailsMaterial;
        materials.glass = glassMaterial;

        carModel.getObjectByName("body").material = bodyMaterial;
        carModel.getObjectByName("interior").material = interiorMaterial;
        carModel.getObjectByName("lights_front").material = glassMaterial;
        carModel.getObjectByName("lights_back").material = lightsMaterial;
        carModel.getObjectByName("rim_fl").material = rimMaterial;
        carModel.getObjectByName("rim_fr").material = rimMaterial;
        carModel.getObjectByName("rim_rr").material = rimMaterial;
        carModel.getObjectByName("rim_rl").material = rimMaterial;
        carModel.getObjectByName("glass").material = glassMaterial;
        carModel.getObjectByName("trim").material = detailsMaterial;

        done(carModel);
      });
    }
    function init() {
      renderer = new THREE.WebGLRenderer({
        alpha: false,
        antialias: false
      });
      renderer.setPixelRatio((window.devicePixelRatio || 1) * 2);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setAnimationLoop(render);
      renderer.shadowMap.enabled = true;
      renderer.shadowMap.type = THREE.PCFSoftShadowMap;
      renderer.outputEncoding = THREE.sRGBEncoding;
      renderer.toneMapping = THREE.ACESFilmicToneMapping;
      renderer.toneMappingExposure = 0.55;
      // Environment
      const environment = new RoomEnvironment();
      const pmremGenerator = new THREE.PMREMGenerator(renderer);
      // Camera
      camera = new THREE.PerspectiveCamera(35, window.innerWidth / window.innerHeight, 0.3, 1000);
      camera.position.set(
        sceneAttributes.camera.position.x,
        sceneAttributes.camera.position.y,
        sceneAttributes.camera.position.z
      );
      camera.rotation.set(
        sceneAttributes.camera.rotation.x,
        sceneAttributes.camera.rotation.y,
        sceneAttributes.camera.rotation.z
      );
      camera.scale.set(
        sceneAttributes.camera.scale.x,
        sceneAttributes.camera.scale.y,
        sceneAttributes.camera.scale.z
      );
      // Controls
      controls = new OrbitControls(camera, $outlet[0]);
      controls.update();
      // Grid
      grid = new THREE.GridHelper(100, 40, 0x000000, 0x000000);
      grid.material.opacity = 0.15;
      grid.material.depthWrite = false;
      grid.material.transparent = true;
      // Scene
      scene = new THREE.Scene();
      scene.background = new THREE.Color(0xf9f7dc);
      scene.environment = pmremGenerator.fromScene(environment).texture;
      scene.fog = new THREE.Fog(0xffffff, 0, 30);
      scene.add(grid);
      // camera.lookAt(scene.position);
      const spotLight = new THREE.SpotLight(0xFFFFFF);
      spotLight.position.set(-40, 60, -10);
      spotLight.castShadow = true;
      spotLight.shadow.mapSize.height = window.innerHeight;
      spotLight.shadow.mapSize.width = window.innerWidth;
      scene.add(spotLight);
      
      return new Promise((resolve) => {
        withCar((carModel) => {
          model = carModel;
          wheels.push(
            carModel.getObjectByName("wheel_fl"),
            carModel.getObjectByName("wheel_fr"),
            carModel.getObjectByName("wheel_rl"),
            carModel.getObjectByName("wheel_rr")
          );
          scene.add(carModel);
          $outlet.append(renderer.domElement);
          render();
          // setupDebug(camera);
          console.debug("3D car stage ready");
          resolve();
        });
      });
    }
    function render() {
      const timestamp = new Date().getTime();
      const time = -timestamp / 1000;
      grid.position.z = -time % 5;
      camera.position.set(
        sceneAttributes.camera.position.x,
        sceneAttributes.camera.position.y,
        sceneAttributes.camera.position.z
      );
      camera.rotation.set(
        sceneAttributes.camera.rotation.x,
        sceneAttributes.camera.rotation.y,
        sceneAttributes.camera.rotation.z
      );
      camera.scale.set(
        sceneAttributes.camera.scale.x,
        sceneAttributes.camera.scale.y,
        sceneAttributes.camera.scale.z
      );
      if (model) {
        model.position.set(
          sceneAttributes.model.position.x,
          sceneAttributes.model.position.y,
          sceneAttributes.model.position.z
        );
        model.rotation.set(
          sceneAttributes.model.rotation.x,
          sceneAttributes.model.rotation.y,
          sceneAttributes.model.rotation.z
        );
        model.scale.set(
          sceneAttributes.model.scale.x,
          sceneAttributes.model.scale.y,
          sceneAttributes.model.scale.z
        );
        if (canRotateWheels) {
          if (wheels.length >= 4) {
            wheels[0].rotation.x = rotationDirection * time * Math.PI;
            wheels[1].rotation.x = rotationDirection * time * Math.PI;
            wheels[2].rotation.x = rotationDirection * time * Math.PI;
            wheels[3].rotation.x = rotationDirection * time * Math.PI;
          }
        }
      }
      renderer.render(scene, camera);
    }
    let firstResize = false;
    return init().then(
      () =>
        new Promise((resolve) => {
          $(window).on("resize", () => {
            if (model) {
              sceneAttributes.model.scale.x = ratio;
              sceneAttributes.model.scale.y = ratio;
              sceneAttributes.model.scale.z = ratio;
              model.scale.set(ratio, ratio, ratio);
            }
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
            if (!firstResize) {
              firstResize = true;
              resolve();
            }
          });
          $(window).on("scroll", () => {
            updateProgress();
          });
          $(window).trigger("resize");
          $(window).trigger("scroll");
        })
    );
  }

  function createHomeAnimation() {
    const contentAnimation = gsap.timeline({
      ease: "power1.inOut",
      scrollTrigger: {
        id: "contentAnimation_home",
        trigger: '[data-section="home"]',
        scrub: 1,
        start: "top top",
        end: "bottom top"
      },
      onUpdate: () => onSectionAnimationProgress("home", contentAnimation.progress())
    });
    contentAnimation.to(".tagline", {
      scale: 1.1,
      alpha: 0
    });
    contentAnimation.fromTo(
      $progressBar[0],
      {
        alpha: 0
      },
      {
        alpha: 1
      }
    );

    triggers["home"] = ["contentAnimation_home"];
    return contentAnimation;
  }

  function createAboutAnimation(options) {
    const section = "about";
    // console.debug($title);
    const contentAnimation = gsap.timeline({
      ease: "power1.inOut",
      scrollTrigger: {
        id: `contentAnimation_${section}`,
        trigger: `[data-section="${section}"]`,
        scrub: 1,
        start: "top center",
        end: "top top"
      },
      onUpdate: () => onSectionAnimationProgress(section, contentAnimation.progress())
    });
    contentAnimation.fromTo(
      `[data-section="${section}"] .sectionContent > h1 > span[line="1"]`,
      {
        opacity: 0.0,
        translateX: -120
      },
      {
        opacity: 1.0,
        translateX: 0
      }
    );
    contentAnimation.fromTo(
      `[data-section="${section}"] .sectionContent > h1 > span[line="2"]`,
      {
        opacity: 0,
        translateX: +120
      },
      {
        opacity: 1,
        translateX: 0
      },
      0
    );
    contentAnimation.fromTo(
      `[data-section="${section}"] .sectionContent ${options.contentSelector}`,
      {
        opacity: 0,
        translateY: +220
      },
      {
        opacity: 1,
        translateY: 0
      },
      0
    );
    triggers[section] = [`contentAnimation_${section}`];
  }

  function createExamplesAnimation() {
    // progressbar color shift
    const colorsInvert = gsap.timeline({
      ease: "power1.inOut",
      scrollTrigger: {
        id: "sectionEntryExamples",
        trigger: '[data-section="examples"]',
        scrub: 1,
        start: "top center",
        end: "top bottom"
      }
    });
    colorsInvert.fromTo(
      $progressBar[0],
      {
        filter: `brightness(1)`
      },
      {
        filter: `brightness(0)`,
        onReverseComplete: () => {
          $progressBar.attr("data-inverted", "no");
          $languagesMenu.attr("data-inverted", "no");
          $miniPageMenu.attr("data-inverted", "no");
          $socialButtons.attr("data-inverted", "no");
        },
        onComplete: () => {
          $progressBar.attr("data-inverted", "yes");
          $languagesMenu.attr("data-inverted", "yes");
          $miniPageMenu.attr("data-inverted", "yes");
          $socialButtons.attr("data-inverted", "yes");
        }
      }
    );
    // text show
    const textEntry = gsap.timeline({
      ease: "power1.inOut",
      scrollTrigger: {
        id: "textEntryCar",
        trigger: '[data-section="examples"]',
        scrub: 1,
        start: "top center",
        end: "bottom bottom"
      }
    });
    textEntry.fromTo(
      '[data-section="examples"] .sectionContent > h1 > span[line="1"]',
      {
        opacity: 0.0,
        translateX: +120
      },
      {
        opacity: 1.0,
        translateX: 0
      },
      0
    );
    textEntry.fromTo(
      '[data-section="examples"] .sectionContent > h1 > span[line="2"]',
      {
        opacity: 0,
        translateX: -120
      },
      {
        opacity: 1.0,
        translateX: 0
      },
      0
    );
    const colorsEntry = gsap.timeline({ paused: true });
    colorsEntry.from(".carColor", {
      // left: 0,
      ease: "power2",
      stagger: 0.15,
      force3D: true,
      y: -40,
      opacity: 0
    });
    // car animation
    const carAnimationTimeline = gsap.timeline({
      ease: "power1.inOut",
      scrollTrigger: {
        id: "carAnimationTimeline",
        trigger: '[data-section="examples"]',
        endTrigger: '[data-section="contact"]',
        scrub: 1,
        start: "top top",
        end: "bottom top",
        pin: true,
      },
      onUpdate: () => onSectionAnimationProgress("examples", carAnimationTimeline.progress())
    });
    const scaleValue = (value, from, to) => {
      const scale = (to[1] - to[0]) / (from[1] - from[0]);
      const capped = Math.min(from[1], Math.max(from[0], value)) - from[0];
      return capped * scale + to[0];
    };
    let p = carAnimationTimeline
      .to(sceneAttributes.camera.position, {
        z: -4.5,
        onUpdate: function () {
          const currentProgress = this.progress();
          canRotateWheels = currentProgress <= 0.9;
          rotationDirection = currentProgress > p ? +1 : -1;
          p = currentProgress;
          if (p >= 0.5) {
            const colorsProgress = scaleValue(p, [0.5, 1], [0, 1]);
            colorsEntry.progress(colorsProgress);
          } else {
            colorsEntry.progress(0);
          }
        },
        onComplete: function () {
          colorsEntry.progress(1);
        },
        onStart: function () {
          // textEntry.kill();
          colorsEntry.progress(0);
        }
      })
      .progress();
    // text hide timeline
    carAnimationTimeline.fromTo(
      '[data-section="examples"] .sectionContent > h1 > span[line="1"]',
      {
        opacity: 1,
        translateX: 0
      },
      {
        opacity: 0.0,
        translateX: -120
      },
      0
    );
    carAnimationTimeline.fromTo(
      '[data-section="examples"] .sectionContent > h1 > span[line="2"]',
      {
        opacity: 1,
        translateX: 0
      },
      {
        opacity: 0,
        translateX: +120
      },
      0
    );
    carAnimationTimeline.fromTo(
      '[data-section="examples"] .sectionContent > p',
      {
        opacity: 1,
        translateY: 0
      },
      {
        opacity: 0,
        translateY: +220
      },
      0
    );
    carAnimationTimeline.to(sceneAttributes.model.rotation, {
      y: Math.PI / 2,
      ease: "none",
      onUpdate: function () {
        canRotateWheels = false;
      }
    });
    // contact
    let p1 = carAnimationTimeline
      .to(sceneAttributes.camera.position, {
        x: 22,
        ease: "none",
        onUpdate: function () {
          const currentProgress = this.progress();
          canRotateWheels = currentProgress <= 0.9;
          rotationDirection = currentProgress > p1 ? +1 : -1;
          p1 = currentProgress;
          colorsEntry.progress(1 - currentProgress);
        }
      })
      .progress();
    /*
    let p2 = carAnimationTimeline
      .to(
        sceneAttributes.camera.position,
        {
          x: 20,
          ease: "none",
          onUpdate: function () {
            const currentProgress = this.progress();
            canRotateWheels = currentProgress <= 0.9;
            rotationDirection = currentProgress > p2 ? +1 : -1;
            p2 = currentProgress;
          }
        },
        "after"
      )
      .progress();
      */
    triggers["examples"] = ["textEntryCar", "carAnimationTimeline"];
  }

  function createGalleryAnimation($section, options) {
    // progressbar color shift
    const colorsInvert = gsap.timeline({
      ease: "power1.inOut",
      scrollTrigger: {
        id: "sectionEntryGallery",
        trigger: '[data-section="gallery"]',
        scrub: 1,
        start: "top center",
        end: "top bottom"
      }
    });
    colorsInvert.to($progressBar[0], {
      filter: `brightness(1)`,
      onReverseComplete: () => {
        $progressBar.attr("data-inverted", "yes");
        $languagesMenu.attr("data-inverted", "yes");
        $miniPageMenu.attr("data-inverted", "yes");
        $socialButtons.attr("data-inverted", "yes");
      },
      onComplete: () => {
        $progressBar.attr("data-inverted", "no");
        $languagesMenu.attr("data-inverted", "no");
        $miniPageMenu.attr("data-inverted", "no");
        $socialButtons.attr("data-inverted", "no");
      }
    });

    // section
    const $sectionContent = $section.find(".sectionContent");
    const $sectionContentTitle = $sectionContent.find("h1");
    const { gallery } = options;
    // console.debug($title);
    const masterAnimation = gsap.timeline({
      onUpdate: () => onSectionAnimationProgress("gallery", contentAnimation.progress())
    });
    const contentAnimation = gsap.timeline({
      ease: "power1.inOut",
      scrollTrigger: {
        id: "contentAnimation_gallery",
        trigger: '[data-section="gallery"]',
        scrub: 1,
        start: "top center",
        end: "bottom bottom",
        onUpdate: () => onSectionAnimationProgress("gallery", contentAnimation.progress())
      }
    });
    contentAnimation.fromTo(
      '[data-section="gallery"] .sectionContent h1',
      {
        opacity: 0.0,
        translateX: -30
      },
      {
        opacity: 1.0,
        translateX: 0
      }
    );
    contentAnimation.fromTo(
      '[data-section="gallery"] .sectionContent p',
      {
        opacity: 0
      },
      {
        opacity: 1
      },
      0
    );
    //
    const sketchImages = gallery.map((it) => it.url);
    const sketchDuration = 1.5;
    const sketch = createGallerySketch({
      duration: sketchDuration,
      images: sketchImages,
      onAnimationStart: (frame) => {
        gsap.to($sectionContentTitle[0], { scrollTo: `#sgt-${frame}` });
      }
    });
    masterAnimation.add(contentAnimation);
    return sketch.setup();
  }

  function createExtrasAnimation(section, options) {
    const colorsInvert = gsap.timeline({
      ease: "power1.inOut",
      scrollTrigger: {
        id: "sectionEntryExtras",
        trigger: '[data-section="extras"]',
        scrub: 1,
        start: "top center",
        end: "top bottom"
      }
    });
    colorsInvert.to($progressBar[0], {
      filter: `brightness(0)`,
      onReverseComplete: () => {
        $progressBar.attr("data-inverted", "yes");
        $languagesMenu.attr("data-inverted", "yes");
        $miniPageMenu.attr("data-inverted", "yes");
        $socialButtons.attr("data-inverted", "yes");
      },
      onComplete: () => {
        $progressBar.attr("data-inverted", "no");
        $languagesMenu.attr("data-inverted", "no");
        $miniPageMenu.attr("data-inverted", "no");
        $socialButtons.attr("data-inverted", "no");
      }
    });
    // console.debug($title);
    const contentAnimation = gsap.timeline({
      ease: "power1.inOut",
      scrollTrigger: {
        id: `contentAnimation_${section}`,
        trigger: `[data-section="${section}"]`,
        scrub: 1,
        start: "top center",
        end: "top top"
      },
      onUpdate: () => onSectionAnimationProgress(section, contentAnimation.progress())
    });
    contentAnimation.fromTo(
      `[data-section="${section}"] .sectionContent > h1 > span[line="1"]`,
      {
        opacity: 0.0,
        translateX: -120
      },
      {
        opacity: 1.0,
        translateX: 0
      }
    );
    contentAnimation.fromTo(
      `[data-section="${section}"] .sectionContent > h1 > span[line="2"]`,
      {
        opacity: 0,
        translateX: +120
      },
      {
        opacity: 1,
        translateX: 0
      },
      0
    );
    contentAnimation.fromTo(
      `[data-section="${section}"] .sectionContent ${options.contentSelector}`,
      {
        opacity: 0,
        translateY: +120
      },
      {
        opacity: 1,
        translateY: 0
      },
      0
    );
    // numbers
    const entry = gsap.timeline({
      ease: "power1.inOut",
      scrollTrigger: {
        id: "extrasView",
        trigger: '[data-section="extras"]',
        scrub: 1,
        start: "top center",
        end: "bottom bottom"
      }
    });
    entry.from('[data-section="extras"] .numberLine', {
      // left: 0,
      stagger: 0.25,
      force3D: true,
      scaleX: 0,
      opacity: 0
    });
    entry.from(
      '[data-section="extras"] .numberValue',
      {
        // left: 0,
        stagger: 0.25,
        force3D: true,
        x: "-100vw",
        opacity: 0
      },
      0
    );
    triggers[section] = [`contentAnimation_${section}`];
  }

  function createContactAnimation(section, options) {
    //
    const colorsInvert = gsap.timeline({
      ease: "power1.inOut",
      scrollTrigger: {
        id: "sectionEntryContact",
        trigger: '[data-section="contact"]',
        scrub: 1,
        start: "top center",
        end: "bottom bottom"
      }
    });
    colorsInvert.to($progressBar[0], {
      filter: `brightness(1)`,
      onReverseComplete: () => {
        $progressBar.attr("data-inverted", "yes");
        $languagesMenu.attr("data-inverted", "yes");
        $miniPageMenu.attr("data-inverted", "yes");
        $socialButtons.attr("data-inverted", "yes");
      },
      onComplete: () => {
        $progressBar.attr("data-inverted", "no");
        $languagesMenu.attr("data-inverted", "no");
        $miniPageMenu.attr("data-inverted", "no");
        $socialButtons.attr("data-inverted", "no");
      }
    });
    // console.debug($title);
    const contentAnimation = gsap.timeline({
      ease: "power1.inOut",
      scrollTrigger: {
        id: `contentAnimation_${section}`,
        trigger: `[data-section="${section}"]`,
        scrub: 1,
        start: "top center",
        end: "bottom bottom"
      },
      onUpdate: () => onSectionAnimationProgress(section, contentAnimation.progress())
    });
    contentAnimation.fromTo(
      `[data-section="${section}"] .sectionContent > h1 > span[line="1"]`,
      {
        opacity: 0.0,
        translateX: -120
      },
      {
        opacity: 1.0,
        translateX: 0
      }
    );
    contentAnimation.fromTo(
      `[data-section="${section}"] .sectionContent > h1 > span[line="2"]`,
      {
        opacity: 0,
        translateX: +120
      },
      {
        opacity: 1,
        translateX: 0
      },
      0
    );
    contentAnimation.fromTo(
      `[data-section="${section}"] .sectionContent ${options.contentSelector}`,
      {
        opacity: 0,
        translateY: +220
      },
      {
        opacity: 1,
        translateY: 0
      },
      0
    );
    triggers[section] = [`contentAnimation_${section}`];
  }

  ////////////////////////////////

  function setupSectionHome() {
    createHomeAnimation();
    const $section = $sections.filter('[data-section="home"]');
    const $tagFocal = $section.find('h1[data-scope="focal"]');
    const $tagFill = $section.find('h1[data-scope="fill"]');
    $(window).on("mousemove", (e) => {
      if (isBookingMode) {
        return false;
      }
      const left = e.pageX - $tagFocal.offset().left;
      const top = e.pageY - $tagFocal.offset().top;
      $tagFocal.css("clip-path", `circle(10px at ${left}px ${top}px)`);
      $tagFill.css(
        "clip-path",
        `polygon(0 calc(0% - calc((100% - ${2 * top}px))), ${left}px ${top}px, ${2 * left}px 100%, 0% 100%)`
      );
    });
  }

  function setupSectionAbout() {
    createAboutAnimation({
      contentSelector: "> p"
    });
  }

  function setupSectionGallery(gallery) {
    // setup content
    const $section = $sections.filter('[data-section="gallery"]');
    // const $sectionContent = $section.find(".sectionContent");
    // const $sectionContentTitle = $sectionContent.find("h1");
    const $sectionGalleryContainer = $section.find(".sectionGalleryContainer");
    const $sectionGalleryPreview = $sectionGalleryContainer.find(".sectionGalleryPreview");
    const galleryWidth = $sectionGalleryPreview.width();
    const galleryHeight = $sectionGalleryPreview.height();
    const isBetween = (x, s, e) => {
      return x === e || (s <= x && x <= e);
    };
    window.addEventListener("mousemove", (e) => {
      if (isBookingMode) {
        return;
      }
      const offset = $sectionGalleryPreview.offset();
      const fy = isBetween(e.pageY, offset.top, offset.top + galleryHeight);
      const fx = isBetween(e.pageX, offset.left, offset.left + galleryWidth);
      isOverGallery = fx && fy;
    });
    return createGalleryAnimation($section, { gallery });
  }

  function setupSectionExamples() {
    const $stage3D = $app.find(`[data-3d-stage="examples"]`);
    const promise = setupSectionExamples3DStage($stage3D);
    createExamplesAnimation();
    return promise;
  }

  function setupSectionExtras() {
    createExtrasAnimation("extras", {
      contentSelector: "> p"
    });
  }

  function setupSectionContact() {
    createContactAnimation("contact", {
      contentSelector: `.sectionContentColumns address`
    });
  }

  function setupMouseCursor() {
    const cursor = $cursor[0];
    const size = Number($cursor.attr("data-cursor-size"));
    gsap.set(cursor, { xPercent: -50, yPercent: -50 });
    const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
    const mouse = { x: pos.x, y: pos.y };
    const speed = 0.4;
    const xSet = gsap.quickSetter(cursor, "x", "px");
    const ySet = gsap.quickSetter(cursor, "y", "px");
    let locked = false;
    cursorAnimation = gsap.fromTo(
      $cursor[0],
      {
        scale: 1.0
      },
      {
        paused: true,
        duration: 0.25,
        width: size,
        height: size,
        scale: 1.0
      }
    );
    let visible = false;
    const updateCursor = (e) => {
      // console.debug(e);
      if (!visible) {
        pos.x = e.x;
        pos.y = e.y;
        gsap.fromTo($cursor[0], { scale: 0 }, { opacity: 1, scale: 1, duration: 1 });
        visible = true;
      }
      mouse.x = e.x;
      mouse.y = e.y;
      const menuPageOpen = $togglePageMenu.attr("data-state") === "opened";
      if (menuPageOpen) {
        $cursor.attr("data-over", "no");
        cursorAnimation.reverse();
      } else {
        if (isOverGallery) {
          if (!locked) {
            $cursor.attr("data-over", "yes");
            locked = true;
            cursorAnimation.play();
          }
        } else {
          if (locked) {
            $cursor.attr("data-over", "no");
            locked = false;
            cursorAnimation.reverse();
          }
        }
      }
    };
    window.addEventListener("mousemove", (e) => updateCursor(e));
    gsap.ticker.add(() => {
      const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());
      pos.x += (mouse.x - pos.x) * dt;
      pos.y += (mouse.y - pos.y) * dt;
      xSet(pos.x);
      ySet(pos.y);
    });
    const cursorPlots = {};
    const chainTween = (group, position) => {
      const { elements } = cursorPlots[group];
      const source = elements.map((m) => m).reverse();
      const selector = source[0];
      // console.debug("Tweening", index, selector);
      const toProps = {
        ease: "sine",
        opacity: 1,
        left: position.x - 320 / 2,
        top: position.y - 160 / 2,
        duration: 1
      };
      // console.debug(toProps);
      gsap.fromTo(selector, { opacity: 1 }, toProps);
      let p = 0;
      while (p < source.length - 1) {
        p += 1;
        // gsap.killTweensOf(source[p]);
        const delay = 0.25 * p;
        // console.debug({ p, delay });
        gsap.fromTo(
          source[p],
          { opacity: 1 },
          {
            ...toProps,
            opacity: 0,
            duration: 0.75,
            delay,
            ease: "sine"
          }
        );
      }
    };
    const controller = {
      changeCursor: (options) => {
        // console.debug("Must change cursor", options, $cursor);
        const { group, mode, info } = options;
        if (mode === "plot") {
          if (!cursorPlots[group]) {
            cursorPlots[group] = {
              stopped: false,
              monitor: null,
              elements: [],
              onMonitorFinished: () => {
                if (cursorPlots[group].stopped) {
                  // console.debug("Hide image stopped", group);
                  clearTimeout(cursorPlots[group].monitor);
                  return;
                }
                // console.debug("Hide images", group);
                gsap.to(`[data-image-group="${group}"]`, {
                  opacity: 0,
                  duration: 0.25
                });
              }
            };
            const { source } = info;
            source.forEach((item, index) => {
              const $cursorPlot = $("<div>").attr({
                class: "cursorPlot",
                "data-image-group": group,
                "data-image-index": index
              });
              $cursorPlot.css({
                "background-image": `url(${item.url})`
              });
              $cursorPlot.offset({
                left: info.position.x - 320 / 2,
                top: info.position.y - 160 / 2,
                force3D: true
              });
              $app.append($cursorPlot);
              cursorPlots[group].elements.push($cursorPlot);
            });
          }
          if (cursorPlots[group]) {
            cursorPlots[group].stopped = true;
          }
          chainTween(group, info.position);
          clearTimeout(cursorPlots[group].monitor);
          cursorPlots[group].stopped = false;
          cursorPlots[group].monitor = setTimeout(cursorPlots[group].onMonitorFinished, 1000);
        }
      },
      restoreCursor: () => {
        // console.debug("Must restore cursor");
        $cursor.attr("data-over", "no");
        cursorAnimation.reverse();
      }
    };
    return controller;
  }

  /*
  function setupDebug() {
    const gui = new dat.gui.GUI();
    gui.remember(sceneAttributes);
    const listenToItem = (name, item) => {
      const itemFolder = gui.addFolder(name);
      const itemPositionFolder = itemFolder.addFolder("Position");

      itemPositionFolder.add(item.position, "x").step(0.01).listen();
      itemPositionFolder.add(item.position, "y").step(0.01).listen();
      itemPositionFolder.add(item.position, "z").step(0.01).listen();

      const itemRotationFolder = itemFolder.addFolder("Rotation");
      itemRotationFolder.add(item.rotation, "x").step(0.01).listen();
      itemRotationFolder.add(item.rotation, "y").step(0.01).listen();
      itemRotationFolder.add(item.rotation, "z").step(0.01).listen();

      const itemScaleFolder = itemFolder.addFolder("Scale");
      itemScaleFolder.add(item.scale, "x").step(0.01).listen();
      itemScaleFolder.add(item.scale, "y").step(0.01).listen();
      itemScaleFolder.add(item.scale, "z").step(0.01).listen();
    };
    // Camera
    listenToItem("Camera", sceneAttributes.camera);
    // Model
    listenToItem("Model", sceneAttributes.model);
  }
  */

  const sections = setupSections();
  // console.debug(JSON.stringify(sections, null, 2));
  const start = ({ gallery }) => {
    window.stage.cursorController = setupMouseCursor();
    return Promise.all([
      setupPlugins(sections, {
        initialProgress: getCurrentNavigationProgress()
      }),
      setupSectionHome(),
      setupSectionAbout(),
      setupSectionGallery(gallery),
      setupSectionExtras(),
      setupSectionExamples(),
      setupSectionContact()
    ]);
  };

  return new Promise((resolve) => {
    window.stage = {
      materials
    };
    const runApp = () => {
      start(deps).then(() => {
        console.debug("Content started");
        resolve();
      });
    };
    const assets = [
      {
        id: "ajax-loader",
        src: "img/ajax-loader.gif"
      },
      {
        id: "ajax-loader",
        src: "img/ajax-loader.gif"
      },
      {
        id: "check-mark",
        src: "img/check-mark.gif"
      },
      {
        id: "error-svg",
        src: "img/error.svg"
      },
      {
        id: "logo-svg",
        src: "img/logo.svg"
      },
      {
        id: "logo-png",
        src: "img/logo.png"
      },
      {
        id: "video-bg",
        src: INTRO_VIDEO
      },
      {
        id: "t1jg-about-smaller",
        src: "img/about/t1jg-about-smaller.jpg"
      },
      {
        id: "cursor-1",
        src: "img/cursors/hover1.jpg"
      },
      {
        id: "cursor-2",
        src: "img/cursors/hover2.jpg"
      },
      {
        id: "cursor-3",
        src: "img/cursors/hover3.jpg"
      },
      {
        id: "car-model",
        src: CAR_MODEL
      },
      {
        id: "wasm",
        src: "wasm/draco_decoder.wasm"
      }
    ];
    const items = [].concat(assets);
    // console.debug("Preloading", items);
    const preloaded = localStorage.getItem("preloaded");
    if (items.length && !preloaded) {
      const queue = new createjs.LoadQueue();
      queue.loadManifest(items);
      queue.on("progress", (e) => {
        console.debug("Progress", e.progress);
      });
      queue.on("complete", () => {
        console.debug("Assets preloaded");
        localStorage.setItem("preloaded", "yes");
        runApp();
      });
    } else {
      runApp();
    }
  });
}
