import React, { useCallback } from "react";
import i18n from "../i18n";

import { languages } from "../languages";

import "./LanguagesMenu.css";

export function LanguagesMenu() {
  const currentLanguage = i18n.language === "en-US" ? "en" : i18n.language;
  const onLanguageSwitch = useCallback((e) => {
    const lng = e.currentTarget.parentNode.getAttribute("data-language");
    i18n.changeLanguage(lng);
  }, []);
  return (
    <ul className="languagesMenu">
      {languages.map((lang) => (
        <li data-language={lang} key={lang} className={currentLanguage === lang ? "active" : ""}>
          <a href={`#/language/${lang}`} onClick={onLanguageSwitch}>
            <span>{lang}</span>
          </a>
        </li>
      ))}
    </ul>
  );
}
