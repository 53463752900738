const en = require("./en");
const fr = require("./fr");
const nl = require("./nl");

const injectSEO = (lang) => {
    const { translations } = lang;
    translations["home.description.seo"] = [
        translations["Custom covering"]
    ];
    translations["about.description.seo"] = [
        [translations["When passion"], translations["Becomes art"]].join(" "),
        translations["about.content"],
        translations["Ceramic treatment"],
        translations["Protection films"],
        translations["Relooking"],
    ];
    translations["gallery.description.seo"] = [
        translations["gallery.description"]
    ];
    translations["extras.description.seo"] = [
        [translations["More covering"], translations["For your car"]].join(" "),
        translations["extras.content"],
        translations["Reconditioning"],
        translations["Technical inspection preparation"],
        translations["Secure guarding"],
        translations["Vehicle transfer"],
        translations["Intermediate sale-purchase"],
        translations["Sport cars rental"],
    ];
    translations["examples.description.seo"] = [
        [translations["Want to change"], translations["The color of your car?"]].join(" "),
        translations["examples.content"],
    ];
    translations["contact.description.seo"] = [
        [translations["Do not hesitate"], translations["To contact us"]].join(" "),
        translations["Request a meeting"],
        translations["Show map"],
    ];
    return lang;
};

module.exports = {
    languages: ["fr", "nl", "en"],
    resources: {
        en: injectSEO(en),
        fr: injectSEO(fr),
        nl: injectSEO(nl),
    }
}
