// jshint ignore: start

import React from "react";
import { withTranslation } from "react-i18next";
import "./gallery.css";

function Section(props) {
  const { t, i18n, items, descriptor } = props;
  return (
    <section id={descriptor.section} data-section={descriptor.section} data-section-label={descriptor.label}>
      <div className="line"></div>
      <div className="sectionHeader">
        <h1>{descriptor.count}</h1>
        <h2>{descriptor.label}</h2>
      </div>
      <div className="sectionContent">
        <h1 className="sectionContentTitle">
          {items.map((it, index) => {
            const title = it.title || `${index + 1}`.padStart(4, '0');
            return (
              <div
                className="sectionContentTitleItem"
                key={`sgt-${i18n.language}${index}`}
                id={`sgt-${index}`}
                data-gallery-index={index}
                data-gallery-frame={it.url}
                data-gallery-title={title}
                data-gallery-description={it.description}
              >
                <span>{title}</span>
              </div>
            );
          })}
        </h1>
        <p className="sectionContentDescription">{t("gallery.description")}</p>
      </div>
      <div className="sectionGalleryContainer">
        <div className="sectionGalleryPreview"></div>
      </div>
    </section>
  );
}

export const GallerySection = withTranslation("translations")(Section);
