// jshint ignore: start

import { VERSION, API_URL } from "./constants";

export const client = {
  register: (registration) => {
    const serviceURL = `${API_URL}/register`;
    return fetch(serviceURL, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(registration)
    }).then((re) => {
      if (re.ok) {
        return re.json();
      }
      throw new Error("Unexpected response");
    });
  },
  fetchConfig: () => {
    const serviceURL = `${API_URL}/config`;
    return fetch(serviceURL, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json"
      },
    }).then((re) => {
      if (re.ok) {
        return re.json();
      }
      throw new Error("Unexpected response");
    });
  },
  fetchGallery: () => {
    const serviceURL = `${API_URL}/gallery`;
    return fetch(serviceURL, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json"
      },
    }).then((re) => {
      if (re.ok) {
        return re.json();
      }
      throw new Error("Unexpected response");
    });
  },
  fetchColors: () => {
    const serviceURL = `${API_URL}/colors`;
    return fetch(serviceURL, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json"
      },
    }).then((re) => {
      if (re.ok) {
        return re.json();
      }
      throw new Error("Unexpected response");
    });
  },
  fetchSections: () => {
    const serviceURL = `sections.json?v=${VERSION}`;
    return fetch(serviceURL, {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json"
      },
    }).then((re) => {
      if (re.ok) {
        return re.json();
      }
      throw new Error("Unexpected response");
    });
  }
};
