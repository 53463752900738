module.exports = {
  translations: {
    About: "A propos",
    "Custom covering": "Custom \n covering",
    Contact: "Contact",
    "Do not hesitate": "N’hésitez pas",
    "To contact us": "À nous contacter",
    Examples: "Échantillonnage",
    Gallery: "Gallérie",
    "General info": "Informations générales",
    Address: "Adresse",
    "Cars covering": "Covering de voitures",
    "in Wallonian Brabant": "en Brabant Wallon",
    "Want to change": "Envie de changer la couleur",
    "The color of your car?": "De votre véhicule?",
    "Request a meeting": "Demander un rendez-vous",
    Register: "Soumettre",
    "Show map": "Voir la carte",
    Colors: "Couleurs",
    Click: "Cliquez",
    "More covering": "En plus de l’esthétique",
    "For your car": "De votre véhicule",
    "When passion": "Quand la passion",
    "Becomes art": "Devient de l’art",
    // registration form
    Protection: "Protection",
    Covering: "Covering",
    Cleaning: "Nettoyage",
    Completed: "Terminé",
    Sorry: "Désolé",
    "Choose a date": "Choisissez une date",
    "Type your full name": "Votre nom entier",
    "Type your email address": "Votre email",
    "Type your phone number": "Votre numéro de téléphone",
    "Type your car maker and model": "Marque et modèle de voiture",
    "Choose services": "Choisissez les services désirés",
    "request.success.title": "Votre requête a été enregistrée",
    "request.success.body": "Un de nos operateurs vous contactera pour finaliser le rendez-vous.",
    "request.failure.title": "Votre requête n’a pas été enregistrée",
    "request.failure.body": "Veuillez essayer plus tard ou appelez-nous",
    // home
    "home.description.seo": "Custom covering",
    // about
    "Ceramic treatment": "Traitement céramique",
    "Protection films": "Films de protection",
    Relooking: "Relooking",
    "about.section1": `Le traitement céramique est l’innovation technologique utilisée pour protéger votre carrosserie. La vraie céramique possède des propriétés exceptionnelles telles que la résistance à la chaleur, au froid, à l’abrasion, aux rayures mais également aux produits chimiques. Le traitement est également applicable sur tout l’intérieur, sièges, volant, tableau de bord.`,
    "about.section2": `Découvrez nos films de protections carrosserie dans notre centre agréé Avery Dennison &reg;, Xpel &reg;, Hexis Bodyfence &reg;. Ces films protecteurs aussi appelé PPF (Paint Protection Film) nouvelle génération destinée à l’automobile et aux motos permet de protéger à 100% votre carrosserie contre les impacts, les micro-rayures, les rayures et même le vandalisme. Ces films de protection sont très résistants aux impacts de pierre et est aussi très résistant aux micro-rayures. Leurs technologie auto-cicatrisante leurs permettent de se régénérer tout seul sous l’action de la chaleur. Nos films de protection carrosserie permettent de protéger durablement et efficacement la peinture de votre voiture mais aussi lui donner une brillance plus intense.`,
    "about.section3": `Vous avez trouvé la voiture qui vous convient mais la couleur ne vous plaît pas trop ? Ce n’est plus un problème avec nos films de couleurs Avery Dennison &reg; supreme wrapping. Vous pourriez changer la couleur en quelques jours, sans le coût d’une peinture. Il existe une multitude de couleurs et textures. De plus votre voiture sera protégée contre le soleil et les micro-rayures.`,
    "about.content": `NL Custom a démarré d’une passion pour les belles automobiles avec plus de 10 ans d’expérience dans le secteur et une maîtrise à toute épreuves. Envie de protéger votre véhicule des agressions de tous les jours? Nous voilà pour vous servir. Découvrez en bas nos options de protection.`,
    // gallery
    "gallery.description": "Études de cas - travail réel, exemples réels - visite animée de notre travail",
    // examples
    "examples.content": `C’est possible sans le coût d’une peinture avec nos films de couleurs Avery Dennison &reg; suprême wrapping. Personnalisez votre véhicule selon vos besoins, goûts et/ou attentes en quelques jours. Scrollez pour tester nos options de couleurs et textures.`,
    // extras
    Extras: "Extras",
    Reconditioning: "Reconditionnement",
    "Technical inspection preparation": "Préparation contrôle technique",
    "Secure guarding": "Gardiennage sécurisé",
    "Vehicle transfer": "Transfert de véhicule",
    "Intermediate sale-purchase": "Intermédiaire vente-achat",
    "Sport cars rental": "Location de voiture de sport",
    "extras.section1": `Remise à neuf de votre véhicule ou nettoyage approfondie, shampoing des sièges, des tapis, du plafonnier et compartiment moteur.`,
    "extras.section2": `Nettoyage de votre véhicule et vérification de tous les points de sécurité pour un passage au contrôle technique.`,
    "extras.section3": `Notre service de gardiennage se charge de garder votre véhicule pendant votre absence. De plus, le transfert de notre dépôt à l’aéroport et le retour aéroport sera pris en charge avec notre partenaire taxi sans supplément.`,
    "extras.section4": `Retour précipité de vacances et vous avez dû laisser votre véhicule sur place. Comment le rapatrier ? Faites appel à nos services.`,
    "extras.section5": `Avec une décennie d’expérience dans l’achat-vente de véhicules, nous mettons notre savoir-faire à votre service. Faites-nous confiance!`,
    "extras.section6": `Bientôt disponible`,
    "extras.content": `Nous vous proposons différents services afin de mieux gérer votre voiture et vous garantir une expérience idéale.`
  }
};
